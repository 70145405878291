import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

const LOG_TAG = '[TRUSTLY KYC]';

export type TrustlyKycResponse = {
    deposit_status: 'Success' | 'Failed' | 'Not initiated' | string;
    failed_kyc_count: number;
    is_user_blocked: boolean;
    verification_message: string;
    verification_success: boolean;
};

export const triggerTrustlyKyc = async ({ transactionId, amount }: { transactionId: string; amount: string }) => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/trustly/kyc`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transaction_id: transactionId, amount }),
    });

    const data = await response.json();
    if (!response.ok) {
        logger.warn(LOG_TAG, data);
        throw data;
    }

    return data;
};

export const useTrustlyKyc = () => {
    return useMutation({
        mutationFn: triggerTrustlyKyc,
    });
};
