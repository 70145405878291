import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import PlayerImagePlaceholder from '@/assets/images/playerImagePlaceholder';
import PlayerImagePlaceholderMasked from '@/assets/images/playerImagePlaceholderMasked';
import { designSystem, withOpacity } from '@/styles/styles';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Text } from '../TextComponent';
import { Box, Image } from '../lib/components';
import { PlayerImageCanvas } from './PlayerImageCanvas';
import { Rectangle } from './types';
import {
    arcTextFontStyle,
    borderRadii,
    cardBorderWidth,
    playerNumberFontStyle,
    sizes,
    teamLogoSizes,
    textSeparatorWidths,
} from './utils';

type Props = {
    playerImageUrl?: string;
    arcText?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    onLoadImage?: () => void;
    imageVariant?: 'small' | 'big';
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    teamSecondaryColor?: string;
    bgColor?: 'gray6' | 'gray5';
};

type PlaceholderProps = {
    onLoad?: () => void;
    placeholderSize?: Rectangle;
    masked?: boolean;
    marginTop?: number;
};

export const PlayerImagePlaceholderContainerWithGradient = memo(
    ({ onLoad, placeholderSize, marginTop, masked = false }: PlaceholderProps) => {
        useEffect(() => {
            if (onLoad) {
                onLoad();
            }
        }, [onLoad]);

        return (
            <Box style={{ marginTop }}>
                {masked ? (
                    <PlayerImagePlaceholderMasked {...placeholderSize} />
                ) : (
                    <PlayerImagePlaceholder {...placeholderSize} />
                )}
            </Box>
        );
    }
);

/**
 * This component represents the new player profile image design with skia components
 * At the moment, we do not have local cache for the images, so this component used based on the flag enable_gradient_player_profile_image
 */
export const PlayerProfileImageWithGradient = ({
    playerImageUrl,
    teamImageUrl,
    onLoadImage,
    imageVariant = 'small',
    style,
    customSize,
    teamColor,
    teamSecondaryColor,
    bgColor = 'gray6',
    arcText,
    playerNumber,
}: Props) => {
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl);
    const [loadedImages, setLoadedImages] = useState(Number(!!playerNumber) + Number(!!arcText));

    useEffect(() => {
        // both player and team image to be loaded
        if (loadedImages === 2) {
            onLoadImage && onLoadImage();
        }
    }, [loadedImages, onLoadImage]);

    const onLoad = useCallback(() => {
        setLoadedImages(prevLoadedImages => prevLoadedImages + 1);
    }, []);

    const containerSize = customSize ?? sizes[imageVariant];
    const teamLogoSize = teamLogoSizes[imageVariant];
    const borderRadius = borderRadii[imageVariant];
    const arcTextFontSize = arcTextFontStyle[imageVariant];
    const playerNumberStyle = playerNumberFontStyle[imageVariant];
    const hasPlayerNumber = typeof playerNumber === 'number';
    const textSeparatorWidth = textSeparatorWidths[imageVariant];

    const teamImageSource = useMemo(() => ({ uri: teamImageUrl }), [teamImageUrl]);
    const lineColor = teamSecondaryColor ? teamSecondaryColor : designSystem.colors.white;

    return (
        <Box
            width={containerSize.width}
            overflow="hidden"
            height={containerSize.height}
            style={[style, styles.container, { borderRadius }]}
            backgroundColor={bgColor}
        >
            <PlayerImageCanvas
                teamColor={teamColor}
                bgColor={bgColor}
                source={playerImageUrl}
                onLoad={onLoad}
                hideImage={isPlaceholderImage || hasPlayerNumber}
                variant={imageVariant}
            />
            <Box position={'absolute'} width="100%">
                {hasPlayerNumber ? (
                    <Box width="100%" alignItems={'center'} paddingTop={arcText ? 's6' : 's8'}>
                        <Text style={[styles.playerNumber, playerNumberStyle]}>{playerNumber}</Text>
                    </Box>
                ) : isPlaceholderImage ? (
                    <PlayerImagePlaceholderContainerWithGradient masked />
                ) : null}
            </Box>

            <Box position="absolute" width="100%" alignItems="center" zIndex={1} bottom={6}>
                {arcText ? (
                    <>
                        {hasPlayerNumber ? (
                            <Box
                                width={textSeparatorWidth}
                                height={2}
                                borderRadius={'r10'}
                                marginVertical={'s6'}
                                style={{ backgroundColor: lineColor }}
                            />
                        ) : null}
                        <Text fontFamily="Oswald-SemiBold" style={arcTextFontSize}>
                            {arcText}
                        </Text>
                    </>
                ) : (
                    <Image source={teamImageSource} resizeMode="contain" style={teamLogoSize} onLoad={onLoad} />
                )}
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        backgroundColor: designSystem.colors.gray6,
        borderCurve: 'continuous',
        borderWidth: cardBorderWidth,
        borderColor: withOpacity(designSystem.colors.white, '0.03'),
    },

    playerNumber: {
        color: designSystem.colors.white,
        fontFamily: 'Oswald-SemiBold',
    },
});
