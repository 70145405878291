import { URLS } from '@/data/config';
import { PaymentMethodType } from '@/feature/deposit/hooks/use-deposit-payment-methods';
import { createActiveWalletJurisdictionHeaders, useActiveWallet } from '@/hooks/use-active-wallet';
import { Product } from '@/hooks/use-jurisdiction';
import { Wallet } from '@/utils/fetch-wallet-balance';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

type WithdrawOptions = {
    name: string;
    iframe: boolean;
    logos: string[];
};

export type WithdrawMethodTypes = PaymentMethodType;

export type WithdrawMethodResponse = {
    amount_bonus_to_lose: string;
    closed_loop_disabled: boolean;
    data: WithdrawMethod[];
    has_used_first_deposit_bonus: boolean;
    wallet: Wallet;
};

export type WithdrawMethod = {
    closed_loop_completed: boolean;
    closed_loop_remain: number;
    iframe: boolean;
    logos: string[];
    max: number | string;
    min: number;
    name: string;
    payment_logos?: string[];
    payment_type_id: string;
    type: WithdrawMethodTypes;
    value: string;
    methods: Record<string, WithdrawOptions>;
    email?: string;
    venmo_id?: string;
    supplier?: string;
    cc_last4?: string;
    cc_token?: string;
    cc_type?: string;
    is_expired?: boolean;
};

type WithdrawalMethodsQueryOptions<TData> = Omit<
    UseQueryOptions<WithdrawMethodResponse, unknown, TData, ReturnType<typeof withdrawalMethodKeys.byProduct>>,
    'queryKey'>;

const getWithdrawalMethods = async () => {
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/withdrawal/methods`, {
        method: 'GET',
        headers: createActiveWalletJurisdictionHeaders(),
    });
    const data = await resp.json();
    if (!resp.ok) {
        throw data;
    }
    return data;
};

export const withdrawalMethodKeys = {
    all: ['withdrawal-methods'] as const,
    byProduct: (product: Product) => [...withdrawalMethodKeys.all, product] as const,
};

/**
 * This is a hook that returns the withdrawal methods.
 * It provides state variables such as the list of withdrawal methods,
 * the loading variable, and the loadWithdrawalMethods function
 * to reload the available withdrawal methods.
 * */
export const useWithdrawalMethods = <TData = WithdrawMethodResponse>(
    options?: WithdrawalMethodsQueryOptions<TData>
) => {
    const { activeWalletProduct } = useActiveWallet();
    return useQuery({
        queryKey: withdrawalMethodKeys.byProduct(activeWalletProduct),
        queryFn: getWithdrawalMethods,
        ...options,
    });
};
