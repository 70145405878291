import { StyleSheet } from 'react-native';

export const SEPARATOR_HEIGHT = 0.33;

export const designSystem = {
    colors: {
        transparent: 'transparent',
        gray10: '#1E1F22',
        gray9: '#787C85',
        gray8: '#101114',
        gray7: '#191A1F', // updated. the old value: #222326
        gray6: '#202126', // recently updated from Brad, the old value: #1E1F24
        gray5: '#2F3038',
        gray4: '#43444F',
        gray3: '#6D707C',
        gray2: '#959AA6',
        gray1: '#EDEFF3',
        white: '#FFFFFF',
        white1: '#EDEFF3', //TODO: rename this after talk with Brad
        green: '#15D115',
        successGreen: '#1ED760',
        red: '#FA052E',
        red1: '#DE1135',
        redError: '#E33156',
        purple: '#9F0CCC',
        purple1: '#C93EF4', //TODO: rename this after talk with Brad
        purple2: '#D95CFF',
        purple3: '#CF51F5',
        magenta: '#D240FF',
        inflated: '#F43DC1',
        brandPrimary: '#B40DE7',
        bonusBlue: '#0571FF',
        lightBlue: '#3DF1F4',
        darkBlue: '#2A4778',
        utilityError: '#FA052E',
        warning: '#EB9500',
        special: '#34E6F7',
        surge: '#F964D7',
        boosted: '#FEDA0C',
        xTwitter: '#000000',
        facebook: '#1877F2',
        divider: '#222326',
    },
    gradients: {
        discountedSurge: ['#F964D7', '#34E6F7'],
        discountedBoosted: ['#FEDA0C', '#34E6F7'],
        surgeBoosted: ['#FEDA0C', '#F964D7'],
        multiple: ['#FEDA0C', '#F964D7', '#34E6F7'],
    },
    shareGradients: {
        whatsapp: ['#0DBE2B', '#5CF676'],
        instagram: ['#6828D3', '#EA2A7E', '#F8BA3D'],
    },
    zIndex: {
        zIndexNegative2: -2,
        zIndexNegative1: -1,
        zIndex0: 0,
        zIndex1: 1,
        zIndex2: 2,
        zIndex3: 3,
        zIndex4: 4,
        zIndex100: 100,
    },
};
// use this if you need a color that has opacity < 1, but you can't add the opacity as a separate prop
// for example for a semitransparent border color
export const withOpacity = (hex, opacity) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${opacity})`;
};

export const common = StyleSheet.create({
    row: { flexDirection: 'row' },
    column: { flexDirection: 'column' },
    spaceBetweenRow: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
    wrap: { flexWrap: 'wrap' },
    justifyCenter: { justifyContent: 'center' },
    justifySpaceBetween: { justifyContent: 'space-between' },
    justifySpaceAround: { justifyContent: 'space-around' },
    justifyEvenly: { justifyContent: 'space-evenly' },
    justifyStart: { justifyContent: 'flex-start' },
    justifyEnd: { justifyContent: 'flex-end' },
    alignStart: { alignItems: 'flex-start' },
    alignEnd: { alignItems: 'flex-end' },
    alignCenter: { alignItems: 'center' },
    selfCenter: { alignSelf: 'center' },
    borderCurve: { borderCurve: 'continuous' },
    full: { width: '100%' },
    halfWidth: { width: '50%' },
    thirdWidth: { width: '33%' },
    width75: { width: '75%' },
    absolute: { position: 'absolute' },
    relative: { position: 'relative' },
    zIndexNegative2: { zIndex: -2 },
    zIndexNegative1: { zIndex: -1 },
    zIndex0: { zIndex: 0 },
    zIndex1: { zIndex: 1 },
    zIndex2: { zIndex: 2 },
    zIndex3: { zIndex: 3 },
    zIndex4: { zIndex: 4 },
    fullHeight: { height: '100%' },
    hairlineHeight: { height: SEPARATOR_HEIGHT },
    padding: { padding: 16 },
    paddingHorizontal: { paddingHorizontal: 16 },
    paddingVertical: { paddingVertical: 24 },
    noPadding: { padding: 0 },
    noOpacity: { opacity: 0 },
    noGrow: { flexGrow: 0 },
    grow: { flexGrow: 1 },
    noShrink: { flexShrink: 0 },
    flex: { flex: 1 },
    backgroundColorTransparent: { backgroundColor: 'transparent' },
    backgroundColorDarkMask: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    rotate180: { transform: [{ rotate: '180deg' }] },
    noOverflow: { overflow: 'hidden' },
    separator: { backgroundColor: designSystem.colors.gray7, height: SEPARATOR_HEIGHT },
    marginLeftAuto: { marginLeft: 'auto' },
    pointerEventsNone: { pointerEvents: 'none' },
    pointerEventsBoxNone: { pointerEvents: 'box-none' },
    shadow: {
        shadowColor: 'rgba(0, 0, 0, 1)',
        shadowOffset: { width: 2, height: -2 },
        shadowOpacity: 0.25,
        shadowRadius: 32,
        elevation: 16,
    },
});

// ! fix for storybook-web
export const commonWeb = StyleSheet.create({
    height100vh: { height: '100vh', minHeight: '100%' },
});
