import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useGetTradingLimitsAndEntryRulesQuery } from '@/api/limits-and-rules/query.generated';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useLocalFlagsStore } from '@/feature/devsettings/hooks/use-dev-settings';
import { useAppResumeEffect } from '@/hooks/use-resume';
import { common, designSystem } from '@/styles/styles';
import { EntryRulesOutput, GameMode, GameType } from '@/types/api.generated';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { useJurisdictionStore } from './use-jurisdiction';
import { user } from './use-user';

type State = {
    allEntryRules: EntryRulesOutput[];
    hydrated: boolean;
    applicantId: string;
    actions: {
        setAllEntryRules: (entryRules: EntryRulesOutput[]) => void;
        setHydrated: () => void;
        setApplicantId: (applicantId: string) => void;
    };
};

const defaultEntryRules = {
    minNumberOfPicks: 0,
    maxNumberOfPicks: 0,
    minNumberOfTeams: 0,
    maxNumberOfBoostedPicks: 0,
    maxNumberOfSpecialPicks: 0,
    gameType: GameType.House,
    gameMode: GameMode.Perfect,
};

export const useGlobalState = createWithEqualityFn<State>()(
    persist(
        set => ({
            multipliers: {},
            allEntryRules: [defaultEntryRules],
            hydrated: false,
            applicantId: '',
            actions: {
                setAllEntryRules: entryRules => {
                    set({ allEntryRules: entryRules });
                },
                setHydrated: () => {
                    set({ hydrated: true });
                },
                // This action and flag are added to the GlobalState to ensure that these values are available on the Web App even after the user is redirected from IdComply site in IDPV flow
                setApplicantId: (applicantId: string) => {
                    set({ applicantId });
                },
            },
        }),
        {
            name: 'global-storage',
            version: 2,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, hydrated, ...rest }: State) => rest,
            onRehydrateStorage: () => store => {
                store?.actions.setHydrated();
            },
        }
    ),
    Object.is
);

export const GlobalStateProvider = ({ children }: PropsWithChildren) => {
    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);
    const [{ data: tradingLimitsAndEntryRules }, fetchTradingLimitsAndRules] = useGetTradingLimitsAndEntryRulesQuery({
        pause: !jurisdiction,
    });
    const { ready: isPhraseLoaded } = useTranslation();

    useAppResumeEffect(
        useCallback(() => {
            // ! postpone request until jurisdiction is available
            if (jurisdiction && (user.session || user.isGuest())) {
                fetchTradingLimitsAndRules({ requestPolicy: 'cache-and-network' });
            }
        }, [fetchTradingLimitsAndRules, jurisdiction])
    );

    useEffect(() => {
        if (jurisdiction && (user.session || user.isGuest())) {
            // for web, useAppResumeEffect is not triggered initially
            fetchTradingLimitsAndRules({ requestPolicy: 'cache-and-network' });
        }
    }, [fetchTradingLimitsAndRules, jurisdiction]);

    const allEntryRules = useMemo(() => {
        return tradingLimitsAndEntryRules?.getAllEntryRules ?? [defaultEntryRules];
    }, [tradingLimitsAndEntryRules?.getAllEntryRules]);

    useEffect(() => {
        const { setAllEntryRules } = useGlobalState.getState().actions;
        setAllEntryRules(allEntryRules);
    }, [allEntryRules]);

    const persistedJurisdictionDataLoaded = useJurisdictionStore(state => state._hydrated);
    const persistedLocalFlagsLoaded = useLocalFlagsStore(state => state._hydrated);
    const persistedGlobalDataLoaded = useGlobalState(state => state.hydrated);
    const persistedDataLoaded =
        persistedJurisdictionDataLoaded && persistedLocalFlagsLoaded && persistedGlobalDataLoaded;

    //only render the content when all persisted data is loaded
    //and when phrase resources are loaded
    const renderContent = persistedDataLoaded && isPhraseLoaded;

    if (!renderContent) {
        return (
            <View
                style={[
                    { backgroundColor: designSystem.colors.purple },
                    common.paddingVertical,
                    common.flex,
                    common.justifyCenter,
                ]}
            >
                <StatusBar barStyle={'light-content'} translucent backgroundColor={designSystem.colors.purple} />
                <LoadingSpinner />
            </View>
        );
    }
    return <>{children}</>;
};
