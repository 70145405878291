import AsyncStorage from '@react-native-async-storage/async-storage';

import { BetValidationData } from '@/feature/betslip-pickem/api/types';
import { BetslipPick, PickAndEventWithStatus, PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { getUpdatedPicks } from '@/feature/betslip-pickem/utils/betslip-projection-changed';
import { useWalletStore } from '@/hooks/use-wallet';
import { Currency, EntryRulesOutput, GameMode } from '@/types/api.generated';
import { createJSONStorage, persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { removeEdgeComboForPlayerIds } from '../utils/betslip-edge-combos';
import { mapValidationDataToGameModes } from '../utils/betslip-utils';
import { EntryAmountValidationData } from './use-betslip-actions';

const DEFAULT_PLAYS = {
    DYNAMIC: { currency: Currency.Usd, amount: undefined },
    PERFECT: { currency: Currency.Usd, amount: undefined },
} as const;

const DEFAULT_VALIDATION_DATA = {
    DYNAMIC: {
        betValidation: undefined,
        amountValidation: { errorMessage: undefined, validEntry: false, maxAllowedEntryAmount: 0 },
    },
    PERFECT: {
        betValidation: undefined,
        amountValidation: { errorMessage: undefined, validEntry: false, maxAllowedEntryAmount: 0 },
    },
} as const;

export type BetslipRefferer = { linkId?: string; entryId?: string };

export interface Betslip {
    betslip: BetslipPick[];
    acceptedEdgeCombos: string[][];
    refferer?: BetslipRefferer;
    plays: Record<GameMode, { amount?: number; currency: Currency }>;
    validationData: Record<
        GameMode,
        { betValidation?: BetValidationData; amountValidation: EntryAmountValidationData }
    >;
    validating: boolean;
    isSyncingPicks: boolean;
    actions: {
        setEntryAmount: (value: number | undefined, gameMode: GameMode) => void;
        setValidationData: (
            data: BetValidationData[],
            allEntryRules: EntryRulesOutput[],
            limitsValidation: Record<GameMode, EntryAmountValidationData>
        ) => void;
        setValidating: (value: boolean) => void;
        setIsSyncingPicks: (value: boolean) => void;
        setCurrency: (value: Currency, gameMode: GameMode) => void;
        selectPick: (pick: BetslipPick) => BetslipPick[];
        removePicks: (picks: { eventId: string; player: PlayerWithTeam }[]) => BetslipPick[];
        updateProjections: (picks: PickAndEventWithStatus[]) => void;
        acceptEdgeCombo: (playerIds: string[]) => void;
        clearBetslip: () => void;
        clearGameMode: (gameMode: GameMode) => void;
        replacePicks: (picks: BetslipPick[], refferer?: BetslipRefferer) => void;
        checkForBalanceError: (gameMode: GameMode) => boolean;
    };
}
export const useBetslipStore = createWithEqualityFn<Betslip>()(
    persist(
        (set, get) => ({
            betslip: [],
            acceptedEdgeCombos: [],
            validationData: DEFAULT_VALIDATION_DATA,
            plays: DEFAULT_PLAYS,
            validating: false,
            isSyncingPicks: false,
            actions: {
                setEntryAmount: (value, gameMode) => {
                    const plays = get().plays;
                    if (plays[gameMode]) {
                        plays[gameMode].amount = value ? Number(value) : undefined;
                    }
                    set({ plays: { ...plays } });
                },
                setValidationData: (allValidationData, allEntryRules, limitsValidation) => {
                    const validationData = mapValidationDataToGameModes(
                        allValidationData,
                        allEntryRules,
                        limitsValidation
                    );
                    set({
                        validationData: validationData,
                        validating: false,
                    });
                },
                setValidating: value => {
                    set({
                        validating: value,
                    });
                },
                setIsSyncingPicks: value => {
                    set({
                        isSyncingPicks: value,
                    });
                },
                setCurrency: (value, gameMode) => {
                    const plays = get().plays;
                    plays[gameMode].currency = value;
                    set({ plays: { ...plays } });
                },
                selectPick: item => {
                    const items = get().betslip;
                    const existingItem = items.find(
                        it => it.player.id === item.player.id && it.eventId === item.eventId
                    );
                    let acceptedEdgeCombos = get().acceptedEdgeCombos;
                    if (existingItem) {
                        items.splice(items.indexOf(existingItem), 1, item);
                        //We are replacing a projection, so the accepted edge combo for this player should be removed
                        acceptedEdgeCombos = removeEdgeComboForPlayerIds([existingItem.player.id], acceptedEdgeCombos);
                    } else {
                        items.push(item);
                    }

                    set({ betslip: [...items], acceptedEdgeCombos: [...acceptedEdgeCombos], refferer: undefined });
                    return items;
                },
                replacePicks: (picks, refferer) => {
                    set({ betslip: picks, acceptedEdgeCombos: [], refferer });
                },
                removePicks: picks => {
                    let filteredItems = [...get().betslip];
                    if (picks.length > 0) {
                        picks.forEach(toRemove => {
                            filteredItems = filteredItems.filter(
                                it => !(it.player.id === toRemove.player.id && it.eventId === toRemove.eventId)
                            );
                        });
                        if (filteredItems.length === 0) {
                            //clear the entire betslip
                            get().actions.clearBetslip();
                            return [];
                        } else {
                            const edgeComboPairs = get().acceptedEdgeCombos;
                            //remove accepted edge combos for removed players
                            const filteredEdgeComboParis = removeEdgeComboForPlayerIds(
                                picks.map(it => it.player.id),
                                edgeComboPairs
                            );
                            set({
                                betslip: filteredItems,
                                acceptedEdgeCombos: filteredEdgeComboParis,
                                refferer: undefined,
                            });
                            return filteredItems;
                        }
                    }
                    return filteredItems;
                },
                updateProjections: (picks: PickAndEventWithStatus[]) => {
                    set(state => {
                        const items = getUpdatedPicks({ betSlipPicks: state.betslip, changedPicks: picks });
                        return { betslip: [...items] };
                    });
                },
                acceptEdgeCombo: (playerIds: string[]) => {
                    set(state => {
                        const edgeCombos = state.acceptedEdgeCombos;
                        return { acceptedEdgeCombos: [...edgeCombos, playerIds] };
                    });
                },
                clearBetslip: () => {
                    set({
                        betslip: [],
                        acceptedEdgeCombos: [],
                        refferer: undefined,
                        plays: {
                            DYNAMIC: { currency: Currency.Usd, amount: undefined },
                            PERFECT: { currency: Currency.Usd, amount: undefined },
                        },
                        validationData: {
                            DYNAMIC: {
                                betValidation: undefined,
                                amountValidation: {
                                    errorMessage: undefined,
                                    validEntry: false,
                                    maxAllowedEntryAmount: 0,
                                },
                            },
                            PERFECT: {
                                betValidation: undefined,
                                amountValidation: {
                                    errorMessage: undefined,
                                    validEntry: false,
                                    maxAllowedEntryAmount: 0,
                                },
                            },
                        },
                    });
                },
                clearGameMode: gameMode => {
                    const setEntryAmount = get().actions.setEntryAmount;
                    setEntryAmount(undefined, gameMode);
                },
                checkForBalanceError: gameMode => {
                    const activePlay = get().plays[gameMode];
                    if (!activePlay?.amount) {
                        return false;
                    }

                    const availableBalance =
                        activePlay.currency === Currency.Fre
                            ? useWalletStore.getState().betrBucks || 0
                            : useWalletStore.getState().realMoneyTotal || 0;

                    return activePlay?.amount > availableBalance;
                },
            },
        }),
        {
            name: 'betslip-storage',
            version: 30,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, validating, ...rest }: Betslip) => rest,
        }
    ),
    Object.is
);
