import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useLinkTo } from '@react-navigation/native';

import { user } from '@/hooks/use-user';
import { common } from '@/styles/styles';
import { handleWebviewMessage } from '@/utils/webview';

import { LoadingSpinner } from './LoadingSpinner';
import WebView, { WebViewMessageEvent, WebViewNavigation, WebViewProps } from './Webview';

interface Props extends WebViewProps {
    uri: string;
    onNavigationStateChange?: (event: WebViewNavigation) => void;
    openNewTabOnWeb?: boolean;
    includeAuthToken?: boolean;
    styles?: StyleProp<ViewStyle>;
}

const CustomWebView = ({
    uri,
    onNavigationStateChange,
    openNewTabOnWeb = false,
    styles = {},
    includeAuthToken,
    ...webViewProps
}: Props) => {
    const linkTo = useLinkTo();
    return (
        <WebView
            {...webViewProps}
            source={{ uri, headers: includeAuthToken ? { Authorization: `Bearer ${user.session?.access_token}` } : {} }}
            originWhitelist={['*']}
            startInLoadingState={true}
            style={[common.backgroundColorTransparent, styles]}
            renderLoading={() => (
                <View style={[common.alignCenter, common.justifyCenter, common.fullHeight]}>
                    <LoadingSpinner />
                </View>
            )}
            onMessage={(event: WebViewMessageEvent) => handleWebviewMessage(event?.nativeEvent, linkTo)}
            onNavigationStateChange={onNavigationStateChange}
            openNewTabOnWeb={openNewTabOnWeb}
        />
    );
};

export default CustomWebView;
