import { Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { DEV, URLS, getEnv } from '@/data';
import { STATES } from '@/feature/kyc/const';
import {
    GlobalConfigurationsDocument,
    GlobalConfigurationsDocumentDataBodyBooleanFlagsSlice,
    GlobalConfigurationsDocumentDataBodyButtonSlice,
    GlobalConfigurationsDocumentDataBodyFtdSettingsSlice,
    GlobalConfigurationsDocumentDataBodyFtdSettingsSlicePrimary,
    GlobalConfigurationsDocumentDataBodyKeyValuePairsSlice,
    GlobalConfigurationsDocumentDataBodyLaunchpromoSlice,
    GlobalConfigurationsDocumentDataBodyRichTextSlice,
    GlobalConfigurationsDocumentDataBodyScreenLinksSlice,
    GlobalConfigurationsDocumentDataBodyScreenLinksSliceItem,
    GlobalConfigurationsDocumentDataBodyStatesSelectorSlice,
    GlobalConfigurationsDocumentDataBodyStatesSelectorSliceItem,
    GlobalConfigurationsDocumentDataBodyStatesSelectorSlicePrimary,
    ProductDocument,
    ProductDocumentDataBodyAppMaintenanceSettingsSlice,
    ProductDocumentDataBodyFlagsSlice,
    ProductDocumentDataBodyKeyValuePairsSlice,
    ProductDocumentDataBodyLeaguesSlice,
    ProductDocumentDataBodyLeaguesSliceItem,
    ProductDocumentDataBodyLobbycomponentsSlice,
    ProductDocumentDataBodyScreenLinksSlice,
    ProductDocumentDataBodyScreenLinksSliceItem,
    ProductDocumentDataBodyTabsettingsSlice,
} from '@/types/prismic.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';
import {
    AppFeatureFlags,
    BooleanFlagKeys,
    findAllSlices,
    findSlice,
    parseBooleanFlags,
    parseButtons,
    parseRichTextSlices,
} from '@/utils/parse-prismic-content';
import { RichTextField } from '@prismicio/client';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export enum Product {
    Pickem = 'pickem',
    Sportsbook = 'betting',
    None = 'none', //indicates a state that is in-eligible for all products
}

export enum ProductConfigKeys {
    QuickAmounts = 'Quick Amounts',
    ContentKeys = 'Content Keys',
    ApiKeys = 'API keys',
    Urls = 'URLS',
    LobbyOrdering = 'LobbyOrdering',
    LobbyFeatureLeague = 'Feature League',
    FeaturedLineups = 'Featured Lineups',
    PickslipConfig = 'PickslipConfig',
}

export enum GlobalConfigKeys {
    Urls = 'Urls',
    ApiKeys = 'ApiKeys',
    SelfExclusionAdd = 'SelfExclusionAdd',
    SelfExclusionAddDefault = 'SelfExclusionAddDefault',
    UserSettings = 'UserSettings',
    AppRateDialogSettings = 'AppRateDialogSettings',
    QuickDepositButtonsFantasy = 'QuickDepositButtonsFantasy',
    BeforeYouDepositInfo = 'BeforeYouDepositInfo',
    FtdPrompt = 'ftdPrompt',
    TransactionSettings = 'TransactionSettings',
    AppUpdate = 'AppUpdate',
}

export type JurisdictionSource = 'detected' | 'manual';

/**
 * All the specific jurisdiction settings should be part of this type
 */
export type JurisdictionSettings = {
    globalSettings?: GlobalSettings;
    productConfig?: ProductConfig | undefined;
    productType: Product;
    products?: ProductConfig[] | undefined;
    ineligibleStates?: string[];
};

export type ProductSettings = {
    withdraw_enabled?: boolean;
    deposit_enabled?: boolean;
    deposit_instant_bank_transfers_enabled?: boolean;
    deposit_debit_card_enabled?: boolean;
    deposit_pay_by_bank_enabled?: boolean;
    deposit_paypal_enabled?: boolean;
    reality_check_enabled?: boolean;
    wallet_switcher_enabled?: boolean;
    native_sportsbook_enabled?: boolean;
    sgp_enabled?: boolean;
    higher_multiplier_toggle_enabled?: boolean;
    search_bar_enabled?: boolean;
    enable_inplay_current_value?: boolean;
    hide_app_bar_raf?: boolean;
    sbk_lobby_featured_events_enabled?: boolean;
    default_opened_scoreboard?: boolean;
    sbk_lobby_featured_bets_enabled?: boolean;
    enable_gradient_player_profile_image?: boolean;
    enable_gradient_player_profile_image_ios?: boolean;
    enable_gradient_player_profile_image_android?: boolean;
    game_tracker_enabled?: boolean;
    scoreboard_enabled?: boolean;
    live_hub_enabled?: boolean;
};

export type ProductConfig = {
    uid: string;
    leagues: ProductDocumentDataBodyLeaguesSliceItem[];
    promohubUID: string;
    settings: ProductSettings;
    keyValuePairs: Record<ProductConfigKeys, Record<string, string>>;
    screenLinks: ProductDocumentDataBodyScreenLinksSliceItem[];
    gameplayOfflineStates: string[];
    lobbyComponents: ProductDocumentDataBodyLobbycomponentsSlice | undefined;
    appMaintenanceConfig?: ProductDocumentDataBodyAppMaintenanceSettingsSlice;
    rafPromotionUid: string;
    tabSettings?: ProductDocumentDataBodyTabsettingsSlice;
};

type GlobalSettings = {
    screen_links: GlobalConfigurationsDocumentDataBodyScreenLinksSliceItem[];
    keyValuePairs: Record<GlobalConfigKeys, Record<string, string>>;
    richTextsSlices: Record<string, RichTextField>;
    buttons: Record<string, { label: string; url: any }>;
    featureFlags: AppFeatureFlags;
    depositSalvageOptions: AppFeatureFlags;
    ftdSetting: GlobalConfigurationsDocumentDataBodyFtdSettingsSlicePrimary | undefined;
    statesSelector: State[];
    stateAvailabilityImage: GlobalConfigurationsDocumentDataBodyStatesSelectorSlicePrimary | undefined;
    launchPromo: GlobalConfigurationsDocumentDataBodyLaunchpromoSlice[] | undefined;
};

type GlobalConfig = {
    title: string;
    globalSettings: GlobalSettings;
    defaultProduct: ProductConfig;
    products: ProductConfig[];
    productType: Product;
    ineligibleStates: string[];
};

export type PrismicState = {
    state_name: string;
};

export type UserGPSData = {
    latitude: number;
    longitude: number;
    timestampMillis: number;
    state: string;
    country: string;
};

/**
 * Store that holds the user US-state (determined at app startup), and the betting mode selected via the product switcher.
 */
interface JurisdictionStore {
    userGpsData: UserGPSData | undefined;
    product: Product;
    products: ProductConfig[];
    jurisdiction: string | undefined;
    jurisdictionSource: JurisdictionSource | undefined;
    jurisdictionSettings: JurisdictionSettings | undefined;
    _hydrated: boolean;
    actions: {
        setHydrated: () => void;
        setGpsData: (data: UserGPSData) => void;
        clearGpsData: () => void;
        setProduct: (product: Product) => void;
        setJurisdictionAndUpdateSettings: (state: string, source: JurisdictionSource) => Promise<JurisdictionSettings>;
        fetchSettings(state: string): Promise<JurisdictionSettings>;
        refetchAndUpdateSettings(): Promise<void>;
    };
}

const parseKeyValuePairs = (slices: ProductDocumentDataBodyKeyValuePairsSlice[] | undefined) => {
    const data: Record<string, Record<string, string>> = {};
    slices?.forEach(slice => {
        const values = slice.items?.reduce((acc: { [x: string]: string }, item) => {
            if (item.key) {
                acc[item.key] = item.value ?? '';
            }
            return acc;
        }, {});
        if (slice.primary.name) {
            data[slice.primary.name] = values;
        }
    });
    return data;
};

export const getProductType = (uid: string): Product => {
    switch (true) {
        case uid.includes('pickem'):
            return Product.Pickem;
        case uid.includes('betting'):
            return Product.Sportsbook;
        default:
            return Product.None;
    }
};

export type State = {
    label: string;
    value: string;
};

// filters out any null state names and returns the corresponding state object
const getStatesWithNames = (
    states: GlobalConfigurationsDocumentDataBodyStatesSelectorSliceItem[] | undefined
): State[] => {
    if (!states) {
        return [];
    }
    return states
        .filter(st => st.state_name !== null)
        .map(
            ({ state_name }) =>
                STATES.find(state => state.value.toLowerCase() === (state_name ?? '').toLowerCase()) || {
                    label: '',
                    value: '',
                }
        );
};

const leaguesSortingFn = (
    league1: ProductDocumentDataBodyLeaguesSliceItem,
    league2: ProductDocumentDataBodyLeaguesSliceItem
): number => {
    if (league1.order_id !== null && league2.order_id !== null) {
        if (league1.order_id < league2.order_id) {
            return -1;
        } else if (league1.order_id > league2.order_id) {
            return 1;
        }
    }
    return 0;
};

const getLeagues = (product: ProductDocument): ProductDocumentDataBodyLeaguesSliceItem[] => {
    const leaguesArrays = findAllSlices<ProductDocumentDataBodyLeaguesSlice>('leagues', product.data.body);
    let defaultLeagues = leaguesArrays?.[0]?.items ?? [];
    //UFC
    const leagues1 = leaguesArrays?.[1]?.items ?? [];
    //PGA
    const leagues2 = leaguesArrays?.[2]?.items ?? [];
    defaultLeagues = defaultLeagues.concat(leagues1).concat(leagues2);
    return defaultLeagues.filter(it => it?.active).sort(leaguesSortingFn);
};

export enum AccountLinksKeys {
    AccountLinks = 'Account Links',
    AccountLinksWeb = 'Account Links Web',
}

const getScreenLinksByKey = (
    slices: ProductDocumentDataBodyScreenLinksSlice[],
    key: AccountLinksKeys
): ProductDocumentDataBodyScreenLinksSlice | undefined => {
    return slices.find(slice => slice?.primary?.screen_name === key);
};

const parseProductConfig = (product: ProductDocument): ProductConfig => {
    const leagues = getLeagues(product);

    const screenLinksSlices = findAllSlices<ProductDocumentDataBodyScreenLinksSlice>('screen_links', product.data.body);

    // retrieve both account links configs
    const webScreenLinksSlice = getScreenLinksByKey(screenLinksSlices, AccountLinksKeys.AccountLinksWeb);
    const mobileScreenLinksSlice = getScreenLinksByKey(screenLinksSlices, AccountLinksKeys.AccountLinks);

    const screenLinksSlice = Platform.select({
        default: mobileScreenLinksSlice,
        // if no links are present for web, default to mobile feature flags
        web: (webScreenLinksSlice?.items || [])?.length > 0 ? webScreenLinksSlice : mobileScreenLinksSlice,
    });

    const flagsSlices = findSlice<ProductDocumentDataBodyFlagsSlice>('flags', product.data.body);

    const settings: ProductSettings =
        flagsSlices?.items?.reduce((acc: { [x: string]: boolean }, flag) => {
            if (flag.key) {
                acc[flag.key] = flag.enabled;
            }
            return acc;
        }, {}) ?? {};

    const keysSlices = findAllSlices<ProductDocumentDataBodyKeyValuePairsSlice>('key_value_pairs', product.data.body);

    const keyValuePairs = parseKeyValuePairs(keysSlices);
    const promohubUID = product.data.promohub_uid ?? '';

    const lobbyComponents = findSlice<ProductDocumentDataBodyLobbycomponentsSlice>(
        'lobbycomponents',
        product.data.body
    );

    const appMaintenanceConfig = findSlice<ProductDocumentDataBodyAppMaintenanceSettingsSlice>(
        'app_maintenance_settings',
        product.data.body
    );

    const tabSettings = findSlice<ProductDocumentDataBodyTabsettingsSlice>('tabsettings', product.data.body);

    return {
        uid: product.uid,
        leagues,
        screenLinks: screenLinksSlice?.items ?? [],
        keyValuePairs,
        settings,
        gameplayOfflineStates: (product.data.gameplay_offline_states ?? []).map(it => it.state_name ?? ''),
        promohubUID,
        lobbyComponents,
        appMaintenanceConfig,
        rafPromotionUid: product.data?.raf_promotion_uid ?? '',
        tabSettings,
    };
};

const parseGlobalSettings = (config: GlobalConfigurationsDocument): GlobalSettings => {
    const data = config.data.body;
    const screenLinksSlice = findSlice<GlobalConfigurationsDocumentDataBodyScreenLinksSlice>('screen_links', data);
    const keysSlices = findAllSlices<GlobalConfigurationsDocumentDataBodyKeyValuePairsSlice>('key_value_pairs', data);
    const richTextSlices = findAllSlices<GlobalConfigurationsDocumentDataBodyRichTextSlice>('rich_text', data);
    const booleanFlagSlices = findAllSlices<GlobalConfigurationsDocumentDataBodyBooleanFlagsSlice>(
        'boolean_flags',
        data
    );
    const ftdSetting = findSlice<GlobalConfigurationsDocumentDataBodyFtdSettingsSlice>('ftd_settings', data)?.primary;
    const statesSelector =
        findSlice<GlobalConfigurationsDocumentDataBodyStatesSelectorSlice>('states_selector', data)?.items || [];
    const stateAvailabilityImage = findSlice<GlobalConfigurationsDocumentDataBodyStatesSelectorSlice>(
        'states_selector',
        data
    )?.primary;
    const keyValuePairs = parseKeyValuePairs(keysSlices);
    const richTextsSlices = parseRichTextSlices(richTextSlices);
    const buttonSlices = findAllSlices<GlobalConfigurationsDocumentDataBodyButtonSlice>('button', data);
    const buttons = parseButtons(buttonSlices);
    const launchPromo = findAllSlices<GlobalConfigurationsDocumentDataBodyLaunchpromoSlice>('launchpromo', data) || [];

    // retrieve both configs for feature flags
    const mobileFeatureFlags = parseBooleanFlags(booleanFlagSlices, BooleanFlagKeys.AppFeatureFlags);
    const webFeatureFlags = parseBooleanFlags(booleanFlagSlices, BooleanFlagKeys.AppFeatureFlagsWeb);

    const featureFlags = Platform.select({
        default: mobileFeatureFlags,
        // if no feature flags are present for web, default to mobile feature flags
        web: Object.keys(webFeatureFlags).length > 0 ? webFeatureFlags : mobileFeatureFlags,
    });

    // retrieve both configs for deposit salvage options
    const mobileDepositSalvageOptions = parseBooleanFlags(
        booleanFlagSlices,
        BooleanFlagKeys.DepositSalvageOptionSettings
    );
    const webDepositSalvageOptions = parseBooleanFlags(
        booleanFlagSlices,
        BooleanFlagKeys.DepositSalvageOptionSettingsWeb
    );

    const depositSalvageOptions = Platform.select({
        default: mobileDepositSalvageOptions,
        // if no deposit salvage options are present for web, default to mobile deposit salvage options
        web: Object.keys(webDepositSalvageOptions).length > 0 ? webDepositSalvageOptions : mobileDepositSalvageOptions,
    });

    return {
        screen_links: screenLinksSlice?.items ?? [],
        keyValuePairs,
        richTextsSlices,
        buttons,
        featureFlags,
        depositSalvageOptions,
        ftdSetting,
        launchPromo,
        statesSelector: getStatesWithNames(statesSelector),
        stateAvailabilityImage: stateAvailabilityImage,
    };
};

// for DEV env we will fetch the prismic configs according to the state so that SBK is the default product as is on mobile
const WebEnvWithDefaultConfigs = isWeb && getEnv() !== DEV;

export const fetchACSConfigs = async (state: string): Promise<GlobalConfig | undefined> => {
    try {
        // For web, we use the same prismic configuration for all states
        // By sending WEB as the state, we get the default configuration
        const configState = WebEnvWithDefaultConfigs ? 'WEB' : state.toLowerCase();
        const response = await fetch(`${URLS.ACS_URL}/${configState}`, {
            method: 'GET',
        });
        const data = await response.json();
        const globalSettings = parseGlobalSettings(data);
        const ineligibleStates: PrismicState[] = data.data.ineligible_states;
        //ignore ineligible states for Web, to ensure we display pickem product in all states
        const isIneligible = isWeb ? false : !!ineligibleStates?.find(it => it.state_name === state);
        const productType = isIneligible ? Product.None : getProductType(data.data?.default_product?.uid);

        return {
            title: data.data?.title,
            globalSettings,
            defaultProduct: parseProductConfig(data.data?.default_product),
            products: data.data?.products.map(parseProductConfig),
            ineligibleStates: ineligibleStates?.map(st => st.state_name) || [],
            productType,
        };
    } catch (e) {
        console.warn('Error fetching ACS config', e);
    }
};

const TAG = '[JurisdictionStore]';

export const useJurisdictionStore = create<JurisdictionStore>()(
    persist(
        (set, get) => {
            return {
                userGpsData: undefined,
                product: Product.None,
                jurisdiction: undefined,
                jurisdictionSource: undefined,
                jurisdictionSettings: undefined,
                productConfig: undefined,
                products: [],
                _hydrated: false,
                actions: {
                    setGpsData: (data: UserGPSData) => {
                        set({ userGpsData: data });
                    },
                    clearGpsData: () => {
                        set({ userGpsData: undefined });
                    },
                    setProduct: product => {
                        logger.info(TAG, 'setProduct', product);
                        const productConfig = get().jurisdictionSettings?.products?.find(it =>
                            it.uid.includes(product)
                        );
                        const settings = get().jurisdictionSettings;
                        set({ product, jurisdictionSettings: { ...settings, productType: product, productConfig } });
                    },
                    setJurisdictionAndUpdateSettings: async (state, source) => {
                        logger.info(TAG, 'set jurisdiction and update settings', state, source);
                        const settings = await get().actions.fetchSettings(state);
                        set({
                            jurisdiction: state,
                            jurisdictionSource: source,
                            jurisdictionSettings: settings,
                            products: settings?.products,
                            product: settings?.productType,
                        });

                        return settings;
                    },
                    fetchSettings: async (state: string) => {
                        logger.info(TAG, 'fetching jurisdiction settings', state);
                        const settings = await fetchACSConfigs(state);

                        return {
                            products: settings?.products ?? [],
                            globalSettings: settings?.globalSettings,
                            productConfig: settings?.defaultProduct,
                            productType: settings?.productType ?? Product.None,
                            ineligibleStates: settings?.ineligibleStates,
                        };
                    },
                    setHydrated: () => {
                        set({ _hydrated: true });
                    },
                    refetchAndUpdateSettings: async () => {
                        const state = get();
                        if (state.jurisdiction) {
                            const settings = await state.actions.fetchSettings(state.jurisdiction ?? '');
                            set({ ...state, jurisdictionSettings: settings });
                        }
                    },
                },
            };
        },
        {
            name: 'jurisdiction-storage',
            version: 6,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, _hydrated, ...rest }: JurisdictionStore) => rest,
            onRehydrateStorage: () => store => {
                store?.actions.setHydrated();
            },
        }
    )
);
