import React, { useCallback, useRef } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import Animated, {
    Easing,
    interpolate,
    interpolateColor,
    useAnimatedProps,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ModalPullDownIndicator } from '@/components/PullDownIndicator';
import { SizedBox } from '@/components/SizedBox';
import {
    MODAL_HANDLE_HEIGHT,
    Overlay,
    useBottomSheetAnimationConfigsReducedMotionWorkaround,
} from '@/feature/alerts/components/Modal';
import { EntryInputAmount } from '@/feature/betslip-pickem/screens/EntryInputAmount';
import { useBackHandler } from '@/hooks/use-back-handler';
import { useWalletStore } from '@/hooks/use-wallet';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isAndroid, isIos } from '@/utils/constants-platform-specific';
import BottomSheet, {
    BottomSheetBackdropProps,
    BottomSheetBackgroundProps,
    BottomSheetFooter,
    BottomSheetFooterProps,
    BottomSheetModal,
    BottomSheetView,
} from '@gorhom/bottom-sheet';

import { QuickAmountsModalContent } from '../components/QuickAmountsModal';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'QuickAmountSheetScreen'>;

export const QUICK_AMOUNT_SHEET_TRANSITION_DURATION = 200;

const QUICK_AMOUNTS_CONTENT_WITHOUT_CURRENCY_SWITCHER_HEIGHT = 244;
const QUICK_AMOUNTS_CONTENT_WITH_CURRENCY_SWITCHER_HEIGHT = 326;

/**
 * This screen renders both the QuickAmountsModalContent and the EntryInputAmount inside a BottomSheet.
 * This way we can animate the transition between the two components based on the bottom sheet position.

 */
export const QuickAmountSheetScreen = ({ route: { params } }: ScreenProps) => {
    const betrBucks = useWalletStore(state => state.betrBucks);
    const shouldShowCurrencySwitcher = betrBucks && betrBucks !== 0;

    const modalRef = useRef<BottomSheetModal>(null);

    const { height } = useWindowDimensions();

    const navigation = useNavigation();

    const insets = useSafeAreaInsets();
    const topOffset = insets.top - MODAL_HANDLE_HEIGHT;
    const finalHeight = height - topOffset;
    // on android, the modal doesn't take into accounts the insets.top correctly, so we add them manually
    const platformAwareFinalHeightSnapPoint = isAndroid ? finalHeight + MODAL_HANDLE_HEIGHT : finalHeight;

    const startTransition = useCallback(() => {
        modalRef.current?.snapToIndex(1);
    }, []);

    const bottomSheetPosition = useSharedValue<number>(0);

    // we need a static value for the height of the container to know how to animate the sheet without any flickering
    const contentHeight = shouldShowCurrencySwitcher
        ? QUICK_AMOUNTS_CONTENT_WITH_CURRENCY_SWITCHER_HEIGHT
        : QUICK_AMOUNTS_CONTENT_WITHOUT_CURRENCY_SWITCHER_HEIGHT;

    const contentHeightWithInset = contentHeight + insets.bottom;
    const quickAmountsSheetHeight = contentHeightWithInset + MODAL_HANDLE_HEIGHT;

    const animationConfigs = useBottomSheetAnimationConfigsReducedMotionWorkaround({
        duration: QUICK_AMOUNT_SHEET_TRANSITION_DURATION,
        easing: Easing.inOut(Easing.ease),
    });

    const backdropComponent = useCallback((props: BottomSheetBackdropProps) => <Overlay {...props} closeOnPress />, []);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                bottomSheetPosition.value,
                [topOffset, quickAmountsSheetHeight, finalHeight],
                [designSystem.colors.gray8, designSystem.colors.gray6, designSystem.colors.gray6]
            ),
        };
    });

    const opacityStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(
                bottomSheetPosition.value,
                [topOffset, quickAmountsSheetHeight / 2, quickAmountsSheetHeight, finalHeight],
                [0, 0, 1, 1],
                'clamp'
            ),
        };
    });

    const reverseOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(
                bottomSheetPosition.value,
                [topOffset, quickAmountsSheetHeight / 2, quickAmountsSheetHeight, finalHeight],
                [1, 1, 0, 0],
                'clamp'
            ),
        };
    });

    const renderFooter = useCallback(
        (footerProps: BottomSheetFooterProps) => (
            <BottomSheetFooter {...footerProps}>
                <SizedBox value={insets.bottom} />
            </BottomSheetFooter>
        ),
        [insets.bottom]
    );

    const renderBackground = useCallback(
        (backgroundProps: BottomSheetBackgroundProps) => {
            return (
                <Animated.View
                    pointerEvents="none"
                    style={[backgroundProps.style, { minHeight: height }, styles.background, animatedStyle]}
                />
            );
        },
        [animatedStyle, height]
    );

    const renderHandle = useCallback(() => {
        return (
            <Animated.View style={[styles.handle, animatedStyle]}>
                <Animated.View style={[styles.indicator, opacityStyle]}>
                    <ModalPullDownIndicator color={designSystem.colors.gray4} />
                </Animated.View>
            </Animated.View>
        );
    }, [animatedStyle, opacityStyle]);

    // we don't to use `useState` since we don't want to re-render the component (causes issues with the transition)
    const currentIndex = useRef(0);

    const goBackWithTransition = useCallback(() => {
        // index = 0 is the quick amounts sheet
        // index = 1 is the entry input amount
        if (currentIndex.current === 0) {
            modalRef.current?.close();
        } else if (currentIndex.current === 1) {
            modalRef.current?.collapse();
        }
        return true;
    }, []);

    useBackHandler(goBackWithTransition);

    const entryInputAnimatedProps = useAnimatedProps(() => {
        const targetOffset = height - quickAmountsSheetHeight;
        const pointerEvents = bottomSheetPosition.value < targetOffset ? ('auto' as const) : ('none' as const);
        return {
            pointerEvents,
        };
    });

    return (
        <BottomSheet
            topInset={0}
            backgroundStyle={{ backgroundColor: designSystem.colors.gray8 }}
            backdropComponent={backdropComponent}
            ref={modalRef}
            animatedPosition={bottomSheetPosition}
            enablePanDownToClose
            snapPoints={[quickAmountsSheetHeight, platformAwareFinalHeightSnapPoint]}
            enableDynamicSizing={false}
            containerHeight={contentHeightWithInset}
            handleComponent={renderHandle}
            onChange={value => {
                currentIndex.current = value;
            }}
            onClose={() => {
                navigation.goBack();
            }}
            animationConfigs={animationConfigs}
            footerComponent={renderFooter}
            backgroundComponent={renderBackground}
        >
            <Animated.View style={[common.flex]}>
                <BottomSheetView style={common.flex}>
                    <Animated.View style={[opacityStyle]}>
                        <QuickAmountsModalContent
                            isScreen
                            dismissModal={() => modalRef.current?.close()}
                            gameMode={params.gameMode}
                            gameType={params.gameType}
                            startTransitionToEntryInput={startTransition}
                        />
                    </Animated.View>
                    <SizedBox value={insets.bottom} />

                    <Animated.View
                        animatedProps={entryInputAnimatedProps}
                        style={[
                            common.flex,
                            common.absolute,
                            common.zIndex1,
                            { height: finalHeight },
                            reverseOpacityStyle,
                        ]}
                    >
                        <EntryInputAmount
                            isModal
                            navigateBackWithModalTransition={() => {
                                modalRef.current?.close();
                            }}
                        />
                        {isIos ? <SizedBox value={MODAL_HANDLE_HEIGHT} /> : null}
                    </Animated.View>
                </BottomSheetView>
            </Animated.View>
        </BottomSheet>
    );
};

const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        padding: 36,
        alignItems: 'center',
    },
    handle: {
        paddingTop: 16,
        paddingBottom: 8,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        height: MODAL_HANDLE_HEIGHT,
        borderCurve: 'continuous',
    },
    indicator: {
        marginTop: -4,
    },
    background: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
});
