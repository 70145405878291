import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeModules } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ContactSupport } from '@/components/ContactSupport';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { TrustlyKycResponse, useTrustlyKyc } from '@/feature/kyc/hooks/use-trustly-kyc';
import { TrustlyStatus } from '@/feature/kyc/types';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { navigateBasedOnKYCCount } from '@/navigation/navigation';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { read } from '@/utils/async-storage';

import { PayPalStatus } from '../utils';

const { PaymentsWebView } = NativeModules;

type DepositStatusProps = NativeStackScreenProps<RootStackParamList, 'DepositStatusModal'>;

export const DepositStatus = ({ route }: DepositStatusProps) => {
    const { selectedAmount, paymentProvider, currentStatus } = route.params;
    const navigation = useNavigation();
    const selectedProduct = useJurisdictionStore.getState().product;
    const root = selectedProduct === Product.Sportsbook ? 'SBKHome' : 'PickemHome';
    const { t } = useTranslation(['kyc', 'common']);

    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
        refetchOnMount: 'always',
    });

    const handleNavigateToIdcomply = useCallback(() => {
        navigateBasedOnKYCCount(navigation, retryCount);
    }, [navigation, retryCount]);

    const handleTrustlyVerificationError = useCallback(() => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_FAILED);
        navigation.navigate('ErrorModal', {
            title: t('trustly_verification_failed_title'),
            subtitle: t('trustly_verification_failed_description'),
            primaryButton: t('common:review_information'),
            handlePress: handleNavigateToIdcomply,
            gestureEnabled: false,
            showNavbarTitle: false,
            showSecondaryButton: false,
            footer: <ContactSupport />,
            closeIconHandlePress: () => navigateHome(navigation, 'lobby'),
        });
    }, [navigation, t, handleNavigateToIdcomply]);

    const handleTrustlyDepositError = useCallback(() => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_FAILED);
        navigation.navigate('SuccessModal', {
            title: t('trustly_deposit_failed_title'),
            subtitle: t('trustly_deposit_failed_description'),
            primaryButton: t('set_gaming_limits'),
            secondaryButton: t('common:go_to_lobby'),
            handlePress: () => navigation.navigate('ResponsibleGaming'),
            handleSecondaryPress: () => navigateHome(navigation, 'lobby'),
            closeIconHandlePress: () => navigateHome(navigation, 'lobby'),
        });
    }, [navigation, t]);

    const handleTrustlyVerificationAndDepositSuccess = useCallback(() => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_SUCCESS);
        navigation.navigate('SuccessfulDepositModal', {
            paymentProvider,
            selectedAmount,
            title: t('trustly_success_title'),
            description: t('trustly_success_description', {
                amount: selectedAmount,
                product: selectedProduct,
            }),
        });
    }, [navigation, t, paymentProvider, selectedAmount, selectedProduct]);

    const { mutate } = useTrustlyKyc();
    const handleTrustlyKyc = useCallback(async () => {
        const transactionId = (await read('trustlyTransactionId')) ?? '';
        mutate(
            {
                transactionId,
                amount: selectedAmount,
            },
            {
                onSuccess: (responseData: TrustlyKycResponse) => {
                    if (!responseData.verification_success) {
                        return handleTrustlyVerificationError();
                    }
                    if (responseData.deposit_status !== 'Success') {
                        return handleTrustlyDepositError();
                    }
                    if (responseData.verification_success && responseData.deposit_status === 'Success') {
                        return handleTrustlyVerificationAndDepositSuccess();
                    }
                },
                onError: () => {
                    handleTrustlyVerificationError();
                },
            }
        );
    }, [
        mutate,
        selectedAmount,
        handleTrustlyVerificationError,
        handleTrustlyDepositError,
        handleTrustlyVerificationAndDepositSuccess,
    ]);

    useEffect(() => {
        PaymentsWebView?.closeWebView();
        if (currentStatus === PayPalStatus.SUCCESS) {
            navigation.navigate('SuccessfulDepositModal', {
                paymentProvider,
                selectedAmount,
            });
        } else if (currentStatus === PayPalStatus.ERROR) {
            navigation.navigate('FailedDepositModal', {
                paymentProvider,
                selectedAmount,
            });
        } else if (currentStatus?.includes(TrustlyStatus.TRANSACTION)) {
            handleTrustlyKyc();
        } else if (currentStatus?.includes(TrustlyStatus.CANCEL)) {
            navigation.navigate('KycSelector');
        } else {
            navigation.reset({
                routes: [
                    {
                        name: root,
                        params: { screen: 'AccountStack' },
                    },
                    {
                        name: 'DepositScreen',
                        params: {
                            selectedAmount,
                        },
                    },
                ],
            });
        }
    }, [
        currentStatus,
        navigation,
        paymentProvider,
        selectedAmount,
        root,
        selectedProduct,
        t,
        handleNavigateToIdcomply,
        handleTrustlyKyc,
    ]);

    return (
        <Box bg="gray8" flex={1} justifyContent="center">
            <LoadingSpinner />
        </Box>
    );
};
