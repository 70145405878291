import { URLS, createJurisdictionHeaders } from '@/data';
import { useMutation, useQuery } from '@tanstack/react-query';

import { CashoutBetError, GetBetCashoutAmountResponse, GetBetCashoutResponse } from './types';
import { betKeys } from './use-bets';

const getCashoutAmount = async (betId: string): Promise<GetBetCashoutAmountResponse> => {
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/cash-out-amount`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bet_group_ids: [betId] }),
    });

    const data = await resp.json();

    if (!resp.ok) {
        throw data;
    }
    return data;
};

const cashoutBet = async (betId: string, amount: number): Promise<GetBetCashoutResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/cash-out-bet`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bet_group_id: betId, cashout_amount: amount }),
    });

    const data = await response.json();

    if (!response.ok) {
        throw new CashoutBetError(data?.message, data?.error_codes?.[0], data?.errors?.extra?.cashable_amount);
    }

    return data.data;
};

/**
 * @param betId - bet id for getting cashout amount
 * @param enabled - boolean to enable or disable the query
 */
export function useCashoutAmountQuery(betId: string, enabled: boolean) {
    return useQuery({
        queryKey: betKeys.cashoutAmount(betId),
        queryFn: async () => await getCashoutAmount(betId),
        enabled,
        select: data => data.data,
        staleTime: 0,
    });
}

export const useCashoutBet = () => {
    return useMutation({
        mutationKey: ['cashout-bet'],

        mutationFn: ({ betId, amount }: { betId: string; amount: number }) =>
            cashoutBet(betId, amount),
    });
};
