import React, { useRef, useState } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from 'react-native-reanimated';

import { useNavigation } from '@react-navigation/native';

import { ScrollableScreen } from '@/components/ScrollableScreen';
import WebView from '@/components/Webview';
import { Box } from '@/components/lib/components';
import { useFetchAndActivateFirebaseRemoteConfigs } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { useLinkingStore } from '@/feature/entry-share/hooks/use-linking';
import { useUser } from '@/hooks/use-user';
import { useCoreNavigation } from '@/navigation/navigation';
import { common, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

import { useLogin } from '../hooks/use-login';
import { useRegister } from '../hooks/use-register';
import { useTrackAuth } from '../hooks/use-track-auth';
import { AuthType, getKeycloakUrl, parseCode } from '../utils';

export type AuthenticationProps = {
    initAuthType: AuthType;
    code?: string;
    sessionState?: string;
    initialEmail?: string;
    registering?: string;
};

export const Authentication = ({
    initAuthType,
    code,
    sessionState,
    initialEmail,
    registering,
}: AuthenticationProps) => {
    const navigation = useNavigation();
    const { openLaunch } = useCoreNavigation();

    const { setAuthUrl, handleTrackAbortAuth } = useTrackAuth();

    const [authType, setAuthType] = useState<AuthType>(initAuthType);

    // Fetch and activate remote configs
    useFetchAndActivateFirebaseRemoteConfigs();

    const login = useLogin(authType === 'login', code, sessionState, !!registering);
    useRegister(authType === 'register', code, sessionState);

    const { authorize, loading } = useUser();
    const hasAuthenticatedStarted = useRef(false);

    const oneLinkData = useLinkingStore(state => state.oneLinkData);

    const handleUrlChange = (url: string) => {
        setAuthUrl(url);
        switch (true) {
            case url.includes('auth'):
                setAuthType('login');
                break;
            case url.includes('registration'):
                setAuthType('register');
                break;
            case url.includes('openid-configuration?session_state'): // on authentication success, we get redirected to this url
                const authCode = parseCode(url); // parse query params
                if (authCode && !hasAuthenticatedStarted.current) {
                    hasAuthenticatedStarted.current = true;
                    if (authType === 'login') {
                        login(authCode);
                    } else if (authType === 'register') {
                        authorize(authCode);
                    }
                }
                return false;
            default:
                // open support urls in modal webview
                const regexSupportUrl = new RegExp('https://support.betr.app/.*');
                if (regexSupportUrl.test(url)) {
                    navigation.navigate('ModalWebView', { uri: url });
                    return false;
                }
        }
        return true;
    };

    const handleOnClose = () => {
        if (navigation.canGoBack()) {
            handleTrackAbortAuth();
            navigation.goBack();
        } else {
            openLaunch();
        }
    };

    const screenTitle = authType === 'login' ? 'Login' : 'Sign Up';

    const showLoader = hasAuthenticatedStarted.current || loading;

    const fadeOutValue = useSharedValue(1);
    const maskFadeOutStyle = useAnimatedStyle(() => ({
        opacity: fadeOutValue.value,
    }));

    if (isWeb) {
        return null;
    }

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: screenTitle, closeIconMode: 'close', onClose: handleOnClose }}
            scrollViewProps={{ contentContainerStyle: { flexGrow: 1 }, style: { paddingHorizontal: 0 } }}
        >
            {showLoader ? (
                <Box style={[common.flex, common.alignCenter, common.justifyCenter]} backgroundColor={'gray8'}>
                    <ActivityIndicator color={'white'} size={'large'} />
                </Box>
            ) : (
                <Box style={common.flex}>
                    <WebView
                        onLoadEnd={() => {
                            //Fade out the mask drawn on top of the webview after the page loads
                            //This should allow the initial content of the webview to load
                            //so would prevent the user seeing black / white flickering
                            fadeOutValue.value = withDelay(250, withTiming(0, { duration: 250 }));
                        }}
                        style={common.backgroundColorTransparent}
                        containerStyle={common.backgroundColorTransparent}
                        cacheEnabled={false}
                        cacheMode={'LOAD_NO_CACHE'}
                        originWhitelist={['*']}
                        javaScriptEnabled={true}
                        thirdPartyCookiesEnabled={false}
                        onMessage={() => {}}
                        onShouldStartLoadWithRequest={req => handleUrlChange(req.url)}
                        source={{
                            uri: getKeycloakUrl(authType, initialEmail, oneLinkData?.data.deep_link_sub1),
                        }}
                    />
                    {/**Draw a mask on top of the webview to mask intial loading flickering */}
                    <Animated.View pointerEvents="none" style={[styles.mask, maskFadeOutStyle]} />
                </Box>
            )}
        </ScrollableScreen>
    );
};

const styles = StyleSheet.create({
    mask: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        backgroundColor: designSystem.colors.gray8,
    },
});
