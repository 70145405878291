import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { RESULTS } from 'react-native-permissions';

import { useNavigation } from '@react-navigation/native';

import BetrLogo from '@/assets/icons/betr-small';
import { Button } from '@/components/ButtonNew';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { requestNotificationPermission } from '@/data/braze';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { CountryDetectError, NoLocationAccessError, actions as locationActions } from '@/hooks/use-location';
import { useResumeEffect } from '@/hooks/use-resume';
import { useCoreNavigation } from '@/navigation/navigation';
import { common } from '@/styles/styles';
import { NCPG_PHONE_NUMBER } from '@/utils/constants';
import { openDialerWithNumber } from '@/utils/dial-phone-number';
import { checkLocationPermissionGranted, requestLocationPermission } from '@/utils/location-utils';
import { logger } from '@/utils/logging';

import { LaunchCarousel } from './LaunchCarousel';

const styles = StyleSheet.create({
    staticHeightContainer: {
        height: 200,
    },
});

export function LaunchScreen() {
    const launchPromos = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.launchPromo?.[0]?.items ?? []
    );
    const navigation = useNavigation();
    const { showIneligibleStateError, showIneligibleCountry } = useCoreNavigation();
    const clearGpsData = useJurisdictionStore(state => state.actions.clearGpsData);

    const [manualUsState, setManualUsState] = useState<string | undefined>(undefined);
    const [detecting, setDetecting] = useState<boolean>(false);

    const bootstrap = useCallback(() => {
        const detectStateByGPS = async () => {
            //Check location permission, or otherwise request it as we can not run without location access
            const isLocationGranted = await checkLocationPermissionGranted();
            if (!isLocationGranted) {
                const status = await requestLocationPermission();
                if (status !== RESULTS.GRANTED) {
                    throw new NoLocationAccessError();
                }
            }
            return await locationActions.detectAndStoreJurisdiction();
        };

        const execute = async () => {
            setDetecting(true);
            try {
                if (!manualUsState) {
                    await detectStateByGPS();
                }
                if (useJurisdictionStore.getState().product === Product.None) {
                    showIneligibleStateError();
                }
            } catch (e) {
                setDetecting(false);
                //Clear data if an error occurs, so we can fetch fresh data if user retries
                clearGpsData();
                logger.warn('[APP START]', e);
                if (e instanceof NoLocationAccessError) {
                    navigation.navigate('AllowLocation', {
                        errorMode: 'locationAccess',
                        onSelectUsState: setManualUsState,
                    });
                    return;
                } else if (e instanceof CountryDetectError) {
                    showIneligibleCountry();
                    return;
                } else {
                    navigation.navigate('AllowLocation', {
                        errorMode: 'countyDetect',
                        onSelectUsState: setManualUsState,
                    });
                    return;
                }
            }
            setDetecting(false);
            //Request notification permission after location prompt to prevent crash from android
            requestNotificationPermission();
        };
        execute();
    }, [manualUsState, showIneligibleStateError, clearGpsData, navigation, showIneligibleCountry]);

    useResumeEffect(bootstrap);

    return (
        <Screen>
            <MaxWidthWrapper flex={1}>
                <Box style={[common.flex, common.justifySpaceBetween]} py="s14">
                    <View style={[common.alignCenter]}>
                        <BetrLogo width={32} height={32} />
                    </View>
                    {launchPromos ? <LaunchCarousel launchPromos={launchPromos} /> : null}
                    <View style={[common.paddingHorizontal, styles.staticHeightContainer]}>
                        {detecting ? <LoadingIndicator /> : <NavigateButtons />}
                        <View style={[common.padding]}>
                            <Text variant={'bodySmaller'} color={'gray3'} textAlign={'center'}>
                                If you or someone you know has a gaming problem, please call the NCPG at {''}
                                <Text
                                    variant="bodySmaller"
                                    textDecorationLine={'underline'}
                                    color={'gray1'}
                                    onPress={() => openDialerWithNumber(NCPG_PHONE_NUMBER)}
                                >
                                    {NCPG_PHONE_NUMBER}
                                </Text>
                            </Text>
                        </View>
                        <SizedBox value={16} />
                    </View>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
}

const LoadingIndicator = () => {
    return (
        <Box justifyContent={'center'} alignItems={'center'} p={'s24'}>
            <ActivityIndicator />
            <Text variant={'bodySmall'} color={'gray2'} textAlign={'center'}>
                Loading...
            </Text>
        </Box>
    );
};

const NavigateButtons = () => {
    const { t } = useTranslation('common');
    const navigation = useNavigation();
    return (
        <Box px="s12" mb="s4">
            <View>
                <SizedBox value={8} />
                <Button
                    label={t('create_account')}
                    variant="emphasis"
                    hierarchy="primary"
                    onPress={() => navigation.navigate('Register')}
                />
            </View>
            <View>
                <SizedBox value={10} />
                <Button label={t('sign_in')} onPress={() => navigation.navigate('Login')} />
            </View>
        </Box>
    );
};
