import { queryClient } from '@/data';
import { unregisterNotification } from '@/data/braze.web';
import { URLS, getAuthRedirectURI } from '@/data/config';
import { closeIntercom } from '@/data/intercom/intercom';
import { EVENT_TYPES, RUDDERSTACK_EVENTS } from '@/feature/analytics/constants';
import { notifyRudderStackUserLogin, trackRudderStackEvent } from '@/feature/analytics/trackRudderStackEvent';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { useLinkingStore } from '@/feature/entry-share/hooks/use-linking';
import { userLimitsKeys } from '@/feature/responsible-gaming/hooks/query-keys';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import {
    clearAllKeysWithExceptions as asyncStorageClearAllKeysWithExceptions,
    read,
    save,
} from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { setCookie } from '@/utils/cookies';
import { logger } from '@/utils/logging';
import { jwtDecode } from 'jwt-decode';
import { proxy, ref, useSnapshot } from 'valtio';

import { useWalletStore } from './use-wallet';

const DAY_IN_SECONDS = 86400;

const KEY_USER_SESSION = 'session';

type UserSession = {
    access_token?: string;
    refresh_token?: string;
    refresh_expires_in?: number;
    expires_in?: number;
    expires_at?: number;
    sub?: string;
    error?: unknown;
} | null;

type UserProfile = {
    email: string;
    email_verified?: string;
    face_id_enabled?: boolean;
    face_id_verified?: boolean;
    family_name: string;
    given_name: string;
    mfa?: boolean;
    mobile_number: string;
    mobile_number_verified?: boolean;
    name?: string;
    preferred_username?: string;
    promo_code?: string;
    sub: string;
};

const TAG = '[User]';

/**
 * Tracks user login and registration events using RudderStack.
 *
 * @param userProfile - The profile of the user to be tracked.
 * @param isWebRegister - Optional flag indicating if the event is a registration event.
 *
 * @remarks
 * - On user login, it sends an identify event to RudderStack.
 * - On user registration, it tracks a registration success event and includes UTM parameters and promo code if available.
 */
const rudderStackTracking = async (userProfile: UserProfile, isWebRegister?: boolean) => {
    // only send identify event to RudderStack on login to reduce the cost
    if (userProfile) {
        notifyRudderStackUserLogin({
            userId: userProfile.sub,
            email: userProfile.email,
        });

        // track registration success event on web
        if (isWebRegister) {
            const utmParameters: Record<string, string> | undefined = await read('utm_parameters');
            const promoCode: string | undefined = await read('promo_code');
            trackRudderStackEvent(RUDDERSTACK_EVENTS.trigger('Registration Success'), {
                eventType: EVENT_TYPES.REGISTRATION,
                promoCode,
                ...utmParameters,
            });
        }
    }
};

export const user = proxy({
    configs: ref({
        scheme: 'betrpickem://auth',
        urls: null as any,
    }),
    loading: false,
    hydrated: false,
    guest: true,
    session: null as UserSession,
    profile: {} as UserProfile,
    _setSession: (session: UserSession) => {
        if (!session) {
            user.session = null;
        } else {
            user.guest = false;
            user.session = Object.assign(
                user.session || {},
                session,
                session.expires_in
                    ? {
                          expires_at:
                              typeof session.expires_at !== 'undefined'
                                  ? session.expires_at
                                  : Date.now() + session.expires_in * 1e3,
                      }
                    : null
            );

            logger.info('USER: SET', new Date(user?.session?.expires_at ?? 0), {
                access_token: user?.session?.access_token?.slice(-20),
                expires_in: user?.session?.expires_in,
                expires_at: user?.session?.expires_at,
                refresh_token: user?.session?.refresh_token?.slice(-20),
                refresh_expires_in: user?.session?.refresh_expires_in,
            });
        }

        return user.session;
    },
    _saveSession: async (session: UserSession) => {
        const userSession = user._setSession(session);

        if (userSession?.error) {
            await save(KEY_USER_SESSION, null);
            return userSession;
        }

        await save(KEY_USER_SESSION, userSession);
        return userSession;
    },
    sessionHasExpired: () => {
        if (!user.hydrated) {
            logger.debug('Calling sessionHasExpired before user being hydrated!');
        }
        return !user.session || (user.session?.expires_at ?? 0) <= Date.now();
    },
    isGuest: () => {
        return user.guest;
    },
});

export const actions = {
    getConfigs: async () => {
        if (!user.configs.urls) {
            user.configs.urls = await (await fetch(URLS.KEYCLOAK_AUTH_REDIRECT_URL)).json();
        }

        return user.configs.urls;
    },

    hydrate: async () => {
        if (user.hydrated) {
            return;
        }
        try {
            const saved = await read(KEY_USER_SESSION);
            if (saved?.access_token && !saved?.error) {
                user._setSession(saved);
                //Load the user profile as part of the hydration step
                actions.loadProfile();
            }
        } finally {
            user.hydrated = true;
        }
    },

    getSession: async () => {
        if (!user.sessionHasExpired()) {
            return user.session;
        }

        const saved = user.session;

        if (saved?.access_token && !saved?.error) {
            user._setSession(saved);

            if (user.sessionHasExpired()) {
                logger.debug(TAG, 'getSession', 'Session is expired. Refresh...');
                return await actions.refresh(saved);
            }
        }

        if (!user.profile?.sub) {
            logger.debug(TAG, 'getSession', 'loadProfile()');
            actions.loadProfile(saved);
        }

        return user.session;
    },
    authorize: async (code: string, isWebRegister?: boolean) => {
        user.loading = true;
        const configs = await actions.getConfigs();

        const body = new URLSearchParams();
        body.append('grant_type', 'authorization_code');
        body.append('client_id', URLS.KEYCLOAK_CLIENT_ID);
        body.append('scope', 'openid offline_access');
        body.append('code', code);
        body.append('redirect_uri', getAuthRedirectURI(isWebRegister));

        const session = await (
            await fetch(configs.token_endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            })
        ).json();

        logger.debug(TAG, 'User Session: Authorized');
        user._saveSession(session);

        actions.loadProfile(session);

        if (user) {
            rudderStackTracking(user.profile, isWebRegister);
        }

        user.loading = false;
    },
    loadProfile: (session?: UserSession) => {
        const token = session?.access_token ?? user.session?.access_token;
        if (!token) {
            return null;
        }
        user.guest = false;
        try {
            setCookie('access_token', token, DAY_IN_SECONDS);
            const decodedJWT = jwtDecode(token);
            Object.assign(user.profile, decodedJWT);
        } catch (e) {
            logger.error(TAG, 'loadProfile', e);
        }

        return user.profile;
    },
    refresh: async (userSession: UserSession) => {
        const configs = await actions.getConfigs();

        const body = new URLSearchParams();
        body.append('grant_type', 'refresh_token');
        body.append('client_id', URLS.KEYCLOAK_CLIENT_ID);
        body.append('scope', 'openid offline_access');
        body.append('refresh_token', userSession?.refresh_token ?? '');

        try {
            const session = await (
                await fetch(configs?.token_endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${userSession?.access_token}`,
                    },
                    body: body.toString(),
                })
            ).json();

            if (session.error) {
                logger.error('[auth] User Session: Error', session);
                return await user._saveSession(null);
            }

            actions.loadProfile(session);

            return await user._saveSession(session);
        } catch (e) {
            logger.error(TAG, 'refresh session failed.', { e });
            console.log({ e, configs });
        }

        return user.session;
    },
    logout: async () => {
        user.loading = true;
        user.guest = true;
        const configs = await actions.getConfigs();
        const token = user.session?.access_token;

        const body = new URLSearchParams();
        body.append('client_id', URLS.KEYCLOAK_CLIENT_ID);
        body.append('refresh_token', user.session?.refresh_token ?? '');

        try {
            await (
                await fetch(configs?.end_session_endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: `Bearer ${token}`,
                    },
                    body: body.toString(),
                })
            ).text();
        } catch (e) {
            logger.error(TAG, 'User Session: Logout', e);
        }

        user._saveSession(null);
        await queryClient.resetQueries({ queryKey: authUserKeys.all });

        queryClient.removeQueries({ queryKey: userLimitsKeys.all });

        useBetslipStore.getState().actions.clearBetslip();

        closeIntercom();

        useLinkingStore.getState().clearHandledSources();

        user.loading = false;

        await asyncStorageClearAllKeysWithExceptions();

        if (isWeb) {
            // unsubscribe a web user from Braze's notification on logout
            unregisterNotification();
        }
    },
    loadPreviousSession: async () => {
        await actions.getSession();
        logger.debug(TAG, 'loaded previous session and user info.', user.profile.sub);
        return user;
    },
    setupGuestSession: () => {
        user.guest = true;
        //Setup a fake wallet of 700$ for guest users
        useWalletStore.getState().actions.setupGuestWallet({
            real_amount: '700',
            total: '700',
            bonus_amount: '0',
            free_amount: '0',
            real_currency: 'USD',
        });
    },
    removeGuestSession: () => {
        user.guest = false;
    },
};

export const useUser = () => {
    const snap = useSnapshot(user);

    return { ...snap, ...actions };
};
