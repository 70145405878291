import { URLS, queryClient } from '@/data';
import { removableCardKeys } from '@/types/removable-cards';
import { useMutation } from '@tanstack/react-query';

import { createActiveWalletJurisdictionHeaders } from './use-active-wallet';

type UpdateExpiredCardPayload = {
    paymentTypeId: string;
    expiryDateYear: number;
    expiryDateMonth: number;
};

/**
 * Sends a PUT request to update an expired card.
 *
 * @param payload.paymentTypeId - `payment_type_id` which is returned from `/deposit/options` or `/withdrawal/methods` endpoints.
 * @param payload.expiryDateYear - The expiry year of the card. YYYY format.
 * @param payload.expiryDateMonth - The expiry month of the card. MM format.
 */
export const updateExpiredCard = async (payload: UpdateExpiredCardPayload) => {
    const { paymentTypeId, expiryDateYear, expiryDateMonth } = payload;

    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/card`, {
        method: 'PUT',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            payment_type_id: paymentTypeId,
            expiry_date_year: expiryDateYear,
            expiry_date_month: expiryDateMonth,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
};

export const useUpdateExpiredCard = () => {
    return useMutation({
        mutationKey: ['update-expired-card'],
        mutationFn: updateExpiredCard,

        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: removableCardKeys.all});
        },
    });
};
