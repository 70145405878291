import { useCallback } from 'react';

import { TabType } from '@/feature/bets-sbk/hooks/types';
import { betKeys, getAllBets } from '@/feature/bets-sbk/hooks/use-bets';
import { useQueryClient } from '@tanstack/react-query';

export const usePrefetchBets = (): ((tab: TabType) => void) => {
    const queryClient = useQueryClient();

    return useCallback(
        async (tab: TabType) => {
            queryClient.removeQueries({queryKey: betKeys.infiniteList({ tab: tab })});
            await queryClient.prefetchInfiniteQuery({
                queryKey: betKeys.infiniteList({ tab: tab }),
                queryFn: () => getAllBets({ tab: tab, page: 1 }),
                initialPageParam: 1,
            });
        },
        [queryClient]
    );
};
