import { URLS, createJurisdictionHeaders } from '@/data';
import { GetEventsQueryParams } from '@/feature/league-hub-sbk/hooks/use-events';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';

import { Event } from '../types';

export const getEvent = async (eventId: string): Promise<Event> => {
    let url = `${URLS.CHAMELON_API_URL}/events/${eventId}`;
    if (eventId === '1') {
        // only event 1 should pass in test=true
        url = url + '?test=true';
    }
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    useEventDetailsCache
        .getState()
        .actions.populate([{ ...(data?.data?.event_details ?? {}), id: data?.data.id, status: data?.data.status }]);

    return data.data;
};

export const eventKeys = {
    all: ['event'] as const,
    featured: () => [...eventKeys.all, 'featured'] as const,
    list: () => [...eventKeys.all, 'list'] as const,
    byLeague: (params: GetEventsQueryParams) => [...eventKeys.list(), { ...params }] as const,
    byId: (eventId: string) => [...eventKeys.all, { eventId }] as const,
    marketCategories: (eventId: string, marketCategoryId: string) =>
        [...eventKeys.byId(eventId), 'market-categories', marketCategoryId] as const,
} as const;

const getMultipleQueriesData = <T>(queryClient: QueryClient, setsOfKeys: QueryKey[]) => {
    return setsOfKeys.flatMap(keys => queryClient.getQueriesData<T>({queryKey: keys}));
};

// Get placeholder data from cache
function getPlaceholderData(queryClient: QueryClient, eventId: string) {
    const queriesData = getMultipleQueriesData<{ data: Event[] }>(queryClient, [
        featuredLeagueKeys.events(),
        eventKeys.list(),
    ]);
    if (!queriesData) {
        return;
    }

    for (const data of queriesData) {
        const event = data[1]?.data.find((e: Event) => e.id === eventId);
        if (event) {
            return {
                ...event,
                market_categories: [],
            };
        }
    }
}

export const useEvent = (eventId: string) => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: eventKeys.byId(eventId),
        queryFn: () => getEvent(eventId),
        placeholderData: getPlaceholderData(queryClient, eventId),
    });
};
