import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { RadioSelector } from '@/components/RadioSelector';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { navigateBasedOnKYCCount } from '@/navigation/navigation';
import { logger } from '@/utils/logging';

import { kycRouteNames } from '../const';

export enum KycProvider {
    TRUSTLY = 'trustly',
    IDCOMPLY = 'idcomply',
}

export const KycSelector = () => {
    const [selectedOption, setSelectedOption] = useState<KycProvider>(KycProvider.TRUSTLY);
    const { t } = useTranslation('kyc');
    const navigation = useNavigation();

    const handleOptionSelect = async (option: KycProvider) => {
        try {
            const userConfig = await getAuthUserConfig();
            const { FAILED_KYC_COUNT = 0 } = userConfig.data.config;
            setSelectedOption(option);
            if (option === KycProvider.TRUSTLY) {
                navigation.navigate(kycRouteNames.ACKNOWLEDGEMENTS, { provider: KycProvider.TRUSTLY });
                return;
            }
            navigateBasedOnKYCCount(navigation, FAILED_KYC_COUNT);
        } catch (error) {
            logger.error('Unable to get user auth config when attempting KYC via Trustly');
        }
    };

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('verify_identity') }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
        >
            <MaxWidthWrapper paddingHorizontal="s16">
                <Box>
                    <Text variant="headlineMedium" mb="s6">
                        {t('choose_a_verification_option')}
                    </Text>
                    <Text color="gray2">{t('selector_screen_description')}</Text>
                    <SizedBox value={24} />
                    <RadioSelector
                        isSelected={selectedOption === KycProvider.TRUSTLY}
                        title={t('trustly_kyc_option_title')}
                        description={t('trustly_kyc_option_description')}
                        onPress={() => {
                            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SELECTED_TRUSTLY);
                            handleOptionSelect(KycProvider.TRUSTLY);
                        }}
                    />
                    <SizedBox value={16} />
                    <RadioSelector
                        isSelected={selectedOption === KycProvider.IDCOMPLY}
                        title={t('idcomply_kyc_option_title')}
                        description={t('idcomply_kyc_option_description')}
                        onPress={() => {
                            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SELECTED_IDCOMPLY);
                            handleOptionSelect(KycProvider.IDCOMPLY);
                        }}
                    />
                </Box>
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
