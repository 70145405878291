import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { designSystem } from '@/styles/styles';

import { RadioItem } from './RadioItem';
import { Text } from './TextComponent';
import { Row } from './lib/components';

type RadioSelectorProps = {
    isSelected: boolean;
    description: string;
    title?: string;
    onPress?: () => void;
};

/**
 * A radio selector component that displays a title, description, and a radio button.
 * Can be clicked to trigger a functionality.
 */
export const RadioSelector = ({ description, isSelected = false, title, onPress }: RadioSelectorProps) => {
    return (
        <TouchableOpacity style={styles.container} onPress={onPress}>
            <Row justifyContent={'space-between'} alignItems={'center'}>
                <Text variant={'titleLarge'} color={'white'}>
                    {title}
                </Text>
                <RadioItem selected={isSelected} />
            </Row>
            <Text color="gray2">{description}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        padding: 20,
        gap: 16,
        borderRadius: 16,
        borderWidth: 2,
        borderColor: designSystem.colors.gray1,
    },
});
