export type TrustlyEstablishData = {
    accessId: string;
    merchantId: string;
    currency: string;
    amount: string;
    merchantReference: string;
    paymentType: string;
    returnUrl: string;
    cancelUrl: string;
    notificationUrl: string;
    description: string;
    kycType: string;
    metadata: {
        flowType: string;
        finishButtonLabelType: string;
    };
    paymentProviderId?: string;
} | null;

export type TrustlyMessage = {
    nativeEvent: {
        data: string;
    };
};

export enum TrustlyStatus {
    TRANSACTION = 'trustly_transaction',
    CANCEL = 'trustly_cancel',
}
