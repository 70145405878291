import React from 'react';
import { StyleSheet } from 'react-native';

import { EventWithTeams, isTeamEvent } from '@/api/events/types/types';
import {
    PickemScoreBoardAccordion,
    PickemScoreBoardWithSubscription,
} from '@/feature/entries-pickem/components/PickemScoreBoardAccordion';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useEventDetail } from '@/hooks/use-sbk-event-details-cache';
import { EventStatus, Status } from '@/types/api.generated';
import { nflFullSeason } from '@/utils/league';

import { BasicEventHeader, BorderTopWrapper, EntrySectionEventHeaderProps } from './BasicEventHeader';

const scoreBoardSupportedLeagues = ['NFL', 'NBA', 'MLB', 'NHL'];
const notStartedStatus = [EventStatus.Scheduled, EventStatus.Delayed];

/**
 * This component renders the header for the event in the entry section
 * it can be the basic header or the one with the Scoreboard
 */
export const EntrySectionEventHeader = ({
    event: pickemEvent,
    status,
    showGameTrackerTooltip,
}: EntrySectionEventHeaderProps) => {
    const isFullSeason = nflFullSeason(pickemEvent?.league);
    const pamEventId = pickemEvent?.dataFeedSourceIds.find(({ source }) => source === 'PAM')?.id;
    const leagueSupportsScoreboard = scoreBoardSupportedLeagues.includes(pickemEvent?.league || '');
    const isFutureEvent = notStartedStatus.includes(pickemEvent?.status || EventStatus.Scheduled);
    const showScoreboard = leagueSupportsScoreboard && !isFullSeason && pamEventId && !isFutureEvent;

    return showScoreboard && isTeamEvent(pickemEvent) ? (
        <TeamEventHeader
            eventInfo={pickemEvent}
            pamEventId={pamEventId}
            status={status}
            showGameTrackerTooltip={showGameTrackerTooltip}
        />
    ) : (
        <BasicEventHeader event={pickemEvent} status={status} />
    );
};

/**
 * This header gets the pam event details and renders the pickem scoreboard
 * if the pam endpoint can't find the event, it will render the basic event header
 * for live events it will render the scoreboard with subscription
 */
const TeamEventHeader = ({
    eventInfo,
    status,
    pamEventId,
    showGameTrackerTooltip,
}: {
    eventInfo: EventWithTeams;
    pamEventId: string;
    status?: Status;
    showGameTrackerTooltip?: boolean;
}) => {
    const { data: event, refetch, error } = useEvent(pamEventId);
    const scoreboardEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.scoreboard_enabled
    );

    const eventDetails = useEventDetail(event?.id ?? '') ?? event?.event_details;

    const showScoreboard = eventDetails?.period_scores?.length || eventDetails?.sport_specific?.inning_details;

    return scoreboardEnabled && event && showScoreboard && !error ? (
        <BorderTopWrapper style={styles.topWrapper}>
            {event.status === 'LIVE' ? (
                <PickemScoreBoardWithSubscription
                    eventInfo={eventInfo}
                    event={event}
                    refetch={refetch}
                    eventDetails={eventDetails}
                    showGameTrackerTooltip={showGameTrackerTooltip}
                />
            ) : (
                <PickemScoreBoardAccordion
                    eventInfo={eventInfo}
                    event={event}
                    eventDetails={eventDetails}
                    showGameTrackerTooltip={showGameTrackerTooltip}
                />
            )}
        </BorderTopWrapper>
    ) : (
        <BasicEventHeader event={eventInfo} status={status} />
    );
};

const styles = StyleSheet.create({
    topWrapper: {
        paddingVertical: 5,
        paddingHorizontal: 3,
    },
});
