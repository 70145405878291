import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ContactSupport } from '@/components/ContactSupport';
import { UpdateExpiryCardForm } from '@/components/UpdateExpiryCardForm';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { RootStackParamList } from '@/navigation/types';
import { extractStatusCode } from '@/utils/extract-status-code-from-error';

type ManageDebitCardExpiryUpdateProps = NativeStackScreenProps<RootStackParamList, 'ManageDebitCardExpiryUpdate'>;

export const ManageDebitCardExpiryUpdate = ({ route }: ManageDebitCardExpiryUpdateProps) => {
    const selectedMethod = route.params.selectedMethod;

    const { t } = useTranslation(['manage_cards', 'wallet', 'common']);
    const navigation = useNavigation();

    const { showToast } = useAlerts();

    const handleSuccessUpdateCard = () => {
        navigation.navigate('ManageDebitCards');
        showToast({ message: t('card_update_successful', { last4Digits: selectedMethod.last4_digits }) });
    };

    const handleDismiss = () => {
        navigation.navigate('ManageExpiredDebitCardDetail', { selectedMethod });
    };

    const handleErrorUpdateCard = (error: unknown) => {
        const status = extractStatusCode(error);
        const is400Error = status !== 'unknown' && status >= 400 && status < 500;

        navigation.navigate('ErrorModal', {
            title: t('wallet:update_expiry_card_error_title'),
            subtitle: is400Error
                ? t('wallet:update_expiry_card_error_subtitle')
                : t('wallet:update_expiry_card_500_error_subtitle'),
            primaryButton: is400Error ? t('common:review_information') : t('common:try_again'),
            showSecondaryButton: is400Error,
            secondaryButton: is400Error ? t('common:dismiss') : '',
            handleSecondaryButtonPress: is400Error ? handleDismiss : undefined,
            closeIconMode: 'back',
            footer: is400Error ? null : <ContactSupport />,
        });
    };

    return (
        <UpdateExpiryCardForm
            paymentTypeId={selectedMethod.payment_type_id}
            onSuccessUpdateCard={handleSuccessUpdateCard}
            onErrorUpdateCard={handleErrorUpdateCard}
            primaryButtonLabel={t('common:save')}
        />
    );
};
