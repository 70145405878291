import { useCallback } from 'react';

import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useBetEjectedAlert } from '@/feature/betslip-sbk/components/betslip-action-tray/hooks/use-bet-ejected-alert';
import { usePrefetchBets } from '@/feature/betslip-sbk/components/betslip-action-tray/hooks/use-prefetch-bets';
import { betStakeLimitKeys } from '@/feature/betslip-sbk/hooks/use-bet-stake-limit';
import { SBK_BETSLIP_LOG_TAG } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetSubmissionSummary } from '@/feature/betslip-sbk/types';
import { userLimitsKeys } from '@/feature/responsible-gaming/hooks/query-keys';
import { logger } from '@/utils/logging';
import { useQueryClient } from '@tanstack/react-query';

import { useBetSuccessSheet } from './use-bet-success-sheet';

// Callback to handle logic when a bet submission is complete
export const useBetSubmissionComplete = () => {
    const { showBetEjectedAlert } = useBetEjectedAlert();
    const { showBetSuccessSheet } = useBetSuccessSheet();

    const queryClient = useQueryClient();

    const prefetchBets = usePrefetchBets();
    return useCallback(
        (success: boolean, summary?: BetSubmissionSummary) => {
            if (success && summary) {
                // Success bet submission
                logger.info(SBK_BETSLIP_LOG_TAG, 'Bet submission success', summary);
                prefetchBets('Open');

                const totalBets = summary.betIds.length;
                GASbkEvents.betSlipInteractionSummary(totalBets, totalBets);
                GASbkEvents.viewBetConfirmation();

                // userLimit needs to be updated after bet submission
                queryClient.invalidateQueries({queryKey: userLimitsKeys.limits()});
                queryClient.invalidateQueries({queryKey: betStakeLimitKeys.all});

                showBetSuccessSheet();
            } else if (!success) {
                showBetEjectedAlert();
            }
        },
        [prefetchBets, queryClient, showBetEjectedAlert, showBetSuccessSheet]
    );
};
