import { StackNavigationProp } from '@react-navigation/stack';

import { GeocomplyPayload } from '@/data/location/types';
import { BetStatus, EventStatus } from '@/feature/bets-sbk/hooks/types';
import {
    EventDetails,
    MarketType,
    OptionStatus,
    OptionType,
    Player,
    Sport,
    Team,
} from '@/feature/event-details-sbk/types';
import { UserSettings } from '@/hooks/use-auth-user-settings';
import { Currency } from '@/types/api.generated';
import { MatchUpdateMessage, OddsUpdateMessageOption } from '@/utils/websocket/types';
import { z } from 'zod';

export const MAX_SELECTIONS = 20;

export const BetTypes = {
    Single: 'SINGLE',
    Combo: 'COMBO',
} as const;

export type BetType = (typeof BetTypes)[keyof typeof BetTypes];

export const TotalStakeErrors = {
    ExceedDailyWagerLimit: 'EXCEED_DAILY_WAGER_LIMIT',
    ExceedMonthlyWagerLimit: 'EXCEED_MONTHLY_WAGER_LIMIT',
    ExceedWeeklyWagerLimit: 'EXCEED_WEEKLY_WAGER_LIMIT',
    ExceedWalletCashBalance: 'EXCEED_WALLET_CASH_BALANCE',
    ExceedWalletBetrBucksBalance: 'EXCEED_BETR_BUCKS_BALANCE',
    ExceedWalletCashBetrBucksBalance: 'EXCEED_WALLET_CASH_BETR_BUCKS_BALANCE',
} as const;

export type TotalStakeError = (typeof TotalStakeErrors)[keyof typeof TotalStakeErrors];

export const StakeInputErrors = {
    BelowMin: 'BELOW_MIN',
    AboveMax: 'ABOVE_MAX',
    ExceedMaxSingleWager: 'EXCEED_MAX_SINGLE_WAGER',
    ExceedSingleWalletBalance: 'EXCEED_SINGLE_WALLET_BALANCE',
    ExceedStakeLimit: 'EXCEED_STAKE_LIMIT',
} as const;

export type StakeInputError = (typeof StakeInputErrors)[keyof typeof StakeInputErrors];

export const AddSelectionErrors = {
    ConflictingSelections: 'CONFLICTING_SELECTIONS',
    MaxSelections: 'MAX_SELECTIONS',
} as const;

export type AddSelectionError = (typeof AddSelectionErrors)[keyof typeof AddSelectionErrors];

export type Selection = {
    id: string;
    optionId: string;
    marketId: string;
    eventId: string;
    isComboEnabled: boolean;
};

export type Bet = {
    id: string;
    betType: BetType;
    stake?: number;
    displayStake?: string;
    stakeInputError?: StakeInputError;
    isBetrBucks?: boolean;
};

export type BetSlipMarket = {
    id: string;
    description: string;
    eventId: string;
    marketType: MarketType;
    isMicroMarket: boolean;
    published: boolean;
    player?: Player;
};

export type BetSlipEvent = {
    id: string;
    start_time: number;
    home_team: Team;
    away_team: Team;
    is_sgp_enabled: boolean;
    sport: Sport;
    event_details: EventDetails;
    status: EventStatus;
};

export type BetSlipOption = {
    id: string;
    odds: number;
    originalOdds: number; // this is the odds value when user pick up the selection
    description: string;
    marketId: string;
    optionType: OptionType;
    status: OptionStatus;
};

export type ClosedSelection = {
    option: BetSlipOption;
    market: BetSlipMarket;
    event: BetSlipEvent;
};

export type SelectionParam = {
    option: BetSlipOption;
    market: BetSlipMarket;
    event: BetSlipEvent;
};

export type ProducerStatus = 'UP' | 'DOWN';

export type BetSubmittedState = {
    selections: Record<string, Selection>;
    events: Record<string, BetSlipEvent>;
    markets: Record<string, BetSlipMarket>;
    options: Record<string, BetSlipOption>;
    singlesBets: Bet[];
    comboBet?: { odds: number; selections: Selection[]; bet: Bet };
};

export type SBKBetSlip = {
    selections: Record<string, Selection>;
    events: Record<string, BetSlipEvent>;
    markets: Record<string, BetSlipMarket>;
    options: Record<string, BetSlipOption>;
    bets: Record<string, Bet>;
    sgpOdds: Record<string, number | false>; // for scripts / combo+ bets "option1id-option2id-option3id" => odds | false
    selectionOrder: string[];
    eventOrder: string[];
    editBetId: string | null;
    showKeyboard: boolean;
    lastToggledSelectionId: string | null;
    totalStakeErrors: TotalStakeError[];
    betSubmissionStatus: BetSubmissionStatus;
    useBetrBucks: boolean;
    submittedBets: Record<string, { status: BetStatus; globalId: string }>;
    submittedState: BetSubmittedState;
    closedSelections: Array<ClosedSelection>;
    oddsChanges: Record<string, number>;
    sgpOddsChanges: Record<string, number>;
    sgpEventDisabled: Record<string, boolean>;
    sgpTemporaryIssue: Record<string, boolean>;
    userSettings: UserSettings | null;
    oddsChangeTimeout: NodeJS.Timeout | null;
    producerStatus: ProducerStatus;
    isSgpFetching: boolean;
    betSubmissionStartTime: number | null;
    keepSelectionsInBetSlip: boolean;
    actions: {
        addSelection: (
            option: BetSlipOption,
            market: BetSlipMarket,
            event: BetSlipEvent,
            onAddSelectionError: (error: AddSelectionError, selectionId?: string) => void
        ) => void;
        addComboFeaturedBet: (
            selections: SelectionParam[],
            onAddSelectionError: (error: AddSelectionError) => void
        ) => void;
        addSgpFeaturedBet: (
            selections: SelectionParam[],
            sgpOdds: Record<string, number>,
            onAddSelectionError: (error: AddSelectionError) => void
        ) => void;
        addSgpPlusFeaturedBet: (
            selections: SelectionParam[],
            sgpOdds: Record<string, number>,
            onAddSelectionError: (error: AddSelectionError) => void
        ) => void;
        removeSelection: (selectionId: string) => void;
        removeFeaturedBet: (selectionIds: string[]) => void;
        clearOddsChanges: () => void;
        clearHigherOddsChanges: () => void;
        acceptAllOddsChanges: () => void;
        toggleComboSelectionStatus: (selectionId: string) => void;
        toggleMultipleSelectionStatus: (selectionIds: string[]) => void;
        updateStake: (betId: string, stake: number, displayStake: string, betType: BetType) => void;
        updateStakeCurrency: (betId: string, currency: Currency) => void;
        updateUserSettings: (settings: UserSettings) => void;
        clearBetSlip: () => void;
        clearBetSlipKeepSelections: () => void;
        placeBets: (userId: string, onFail: (error: unknown) => void) => Promise<void>;
        setEditingBet: (betId: string | null) => void;
        setShowKeyboard: (show: boolean) => void;
        toggleUseBetrBucks: () => void;
        updateSgpOdds: ({
            forceUpdate,
            updateEventId,
            onConflictingError,
            shouldHandleOddsChangeFlow,
            isWebsocketUpdate,
        }: {
            forceUpdate?: boolean;
            updateEventId?: string;
            onConflictingError?: () => void;
            shouldHandleOddsChangeFlow?: boolean;
            isWebsocketUpdate?: boolean;
        }) => Promise<void>;
        updateSgpOddsForAllEvents: () => void;
        removeSgpOddsByEventId: (eventId: string) => void;
        updateStakeInputErrors: (betId: string, error?: StakeInputError) => void;
        updateTotalStakeErrors: (errors?: TotalStakeError[]) => void;
        handleOddsUpdateMessages: (oddsUpdate: OddsUpdateMessageOption[]) => void;
        handleMatchUpdateMessage: (matchUpdate: MatchUpdateMessage) => void;
        updateSubmittedBetStatus: (
            betId: string,
            status: BetStatus,
            globalBetId: string,
            rejectionReason: string,
            onComplete: (success: boolean, summary?: BetSubmissionSummary) => void
        ) => void;
        clearClosedSelections: () => void;
        removeClosedSelections: () => void;
        updateProducerStatus: (status: ProducerStatus) => void;
        pollSubmittedBets: (onPollFinish: (success: boolean, summary?: BetSubmissionSummary) => void) => Promise<void>;
        updateBetSubmissionStatus: (betSubmissionStatus: 'SUCCESS' | 'ERROR') => void;
        updateAllEventDetails: () => Promise<void>;
        toggleKeepSelectionsInBetSlip: () => void;
    };
};

export const BetSubmissionStatus = {
    Idle: 'IDLE',
    Submitting: 'SUBMITTING',
    Success: 'SUCCESS',
    Error: 'ERROR',
} as const;

export type BetSubmissionStatus = (typeof BetSubmissionStatus)[keyof typeof BetSubmissionStatus];

export type TabButton = {
    title: string;
    keyTab: BetSlipTab;
    pageContent: JSX.Element;
};

export type BetSlipTab = 'Combo' | 'Scripts' | 'Singles';

export type BetSlipParamsList = {
    [K in BetSlipTab]: undefined;
};

export type BetSlipTabScreenProps = StackNavigationProp<BetSlipParamsList, 'Combo'>;

export type BetSubmissionSummary = {
    totalCount: number;
    wageredAmount: number;
    betIds: string[];
};

export class GeoComplyError extends Error {
    payload: GeocomplyPayload;

    constructor(payload: GeocomplyPayload) {
        super('Geocomply error');
        this.payload = payload;
    }
}

type BetErrorParams = {
    amount?: string;
    // TODO: add additional fields here for dynamic translation depending on the error code above
};

type BetPlacementErrorCodes =
    | 'generic_fallback'
    | 'market_limit_exceeded'
    | 'custom_odds_are_different_from_db'
    | 'huddle_service_odds_are_different_than_requested'
    | 'custom_unable_to_find_market_or_betting_on_blocked_content'; // add all error codes here

export class SubmitBetError extends Error {
    code: BetPlacementErrorCodes;
    params?: BetErrorParams;

    constructor(message: string, code: BetPlacementErrorCodes, params: BetErrorParams) {
        super(message);
        this.code = code;
        this.params = params;
    }
}

export class GenerateBetsPayloadError extends Error {
    code: string;
    msg: string;

    constructor(code: string) {
        super('Generate bets payload error');
        this.msg = 'Generate bets payload error';
        this.code = code;
    }
}

export type SgpPayload = {
    event_id: string;
    bets: Array<{
        side_bet_id: string;
        side_bet_option_id: string;
    }>;
};

export type GetSgpOddsResponse = {
    data: {
        odds: number;
    };
};

export const ComboBetTypes = {
    Parlay: 'PARLAY',
    SGP: 'SGP',
    SGP_Plus: 'SGP+',
} as const;

export type ComboBetType = (typeof ComboBetTypes)[keyof typeof ComboBetTypes];

export const sgpTemporaryErrorCodeSchema = z.enum([
    'sgp_temporarily_unavailable',
    'custom_market_is_not_open',
    'custom_unable_to_find_market',
    'custom_market_is_suspended',
    'custom_market_does_not_have_options',
    'custom_option_does_not_belong_to_market',
    'custom_option_does_not_have_bet_value',
    'custom_selection_is_suspended',
    'custom_selection_is_not_open',
    //Huddle API returned error
    'sgp_market_is_not_open',
    'sgp_market_not_found',
    'sgp_custom_selection_is_suspended',
    'sgp_not_available_pricing',
    'sgp_matrix_not_available',
    'sgp_unknown_error_code',
]);

export const sgpDisabledErrorCodeSchema = z.enum([
    'sgp_not_enabled',
    'sgp_service_error',
    'sgp_not_allowed_for_event',
    'sgp_event_not_found',
]);

export const sgpConflictingErrorCodeSchema = z.enum([
    'conflicting_selections',
    'custom_markets_are_duplicated',
    'custom_markets_option_are_duplicated',
]);

const sgpErrorCodeSchema = sgpTemporaryErrorCodeSchema.or(sgpDisabledErrorCodeSchema).or(sgpConflictingErrorCodeSchema);

export type SgpBetErrorCodes = z.infer<typeof sgpErrorCodeSchema>;

export class SgpBetError extends Error {
    code: SgpBetErrorCodes;

    constructor(message: string, code: SgpBetErrorCodes) {
        super(message);
        this.code = code;
    }
}
