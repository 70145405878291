import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { EntryDetails } from '@/api/entries/types/types';
import { EventInfo } from '@/api/events/types/types';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Column } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useCountdownSeconds } from '@/hooks/use-countdown';
import { formatTime } from '@/hooks/use-format-period';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { add } from 'date-fns';

export type CancelEntryProps = {
    entry: EntryDetails;
    events: EventInfo[];
};

export type CancelEntryModalRef = {
    show: (data: CancelEntryProps) => void;
    dismiss: () => void;
};

const modalId = 'cancelEntryModal';

export const CancelEntryModal = React.forwardRef<CancelEntryModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<CancelEntryProps | null>(null);
    const [targetDate, setTargetDate] = useState<Date | null>(null);

    useImperativeHandle(ref, () => ({
        show: modalProps => {
            setData(modalProps);
            const now = new Date();
            setTargetDate(add(now, { seconds: 10 }));
            modalRef.current?.present();
        },
        dismiss: () => {
            setData(null);
            setTargetDate(null);
            modalRef.current?.dismiss();
        },
    }));

    const modalRef = useRef<BottomSheetModal>(null);

    return (
        <Modal id={modalId} modalRef={modalRef}>
            {data ? (
                <ModalContent {...data} targetDate={targetDate} dismissModal={() => modalRef.current?.dismiss()} />
            ) : null}
        </Modal>
    );
});

const ModalContent = ({
    entry,
    targetDate,
    dismissModal,
    events,
}: CancelEntryProps & { targetDate: Date | null; dismissModal: () => void }) => {
    const { t } = useTranslation('cancel_entry');
    const { navigate } = useNavigation();
    const timeLeft = useCountdownSeconds(targetDate || new Date());

    useEffect(() => {
        if (timeLeft === 0) {
            BetrAnalytics.trackEvent(AnalyticsEvent.CONFIRM_CANCEL_ENTRY_TIMEOUT);
            dismissModal();
        }
    }, [dismissModal, timeLeft]);

    return (
        <Column paddingHorizontal={'s16'}>
            <Text
                variant={'headlineMedium'}
                textAlign={'center'}
                paddingHorizontal={'s24'}
                paddingTop={'s12'}
                paddingBottom={'s24'}
            >
                {t('modal.title')}
            </Text>
            <Box gap={'s12'}>
                <Button
                    label={t('modal.confirm', { timeLeft: formatTime(timeLeft) })}
                    type={'active'}
                    disabled={!entry?.id}
                    variant={'rounded'}
                    size={'medium'}
                    onPress={() => {
                        if (entry?.id) {
                            BetrAnalytics.trackEvent(AnalyticsEvent.CANCEL_ENTRY_YES);
                            dismissModal();
                            navigate('CancelEntry', { entryId: entry?.id, events });
                        }
                    }}
                />
                <Button
                    label={t('modal.cancel')}
                    type={'activeGray'}
                    variant={'rounded'}
                    size={'medium'}
                    onPress={() => {
                        BetrAnalytics.trackEvent(AnalyticsEvent.CANCEL_ENTRY_NO);
                        dismissModal();
                    }}
                />
            </Box>
        </Column>
    );
};
