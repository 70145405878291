import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { useMutation } from '@tanstack/react-query';

export enum SelfExcludeType {
    SELF_EXCLUDE = 'SELF_EXCLUDE',
    TIME_OUT = 'TIME_OUT',
}

type SelfExcludePayload = {
    selfExcludeHours: number; // -1 for permanent exclusion
    selfExcludeType: SelfExcludeType;
};

const updateRGTimeout = async ({ selfExcludeHours, selfExcludeType }: SelfExcludePayload) => {
    const body = JSON.stringify({
        self_exclude_hours: selfExcludeHours,
        self_exclude_type: selfExcludeType,
    });
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/self-exclude`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body,
    });
    if (!resp.ok) {
        throw await resp.json();
    }
    return resp;
};

/**
 * Custom react query mutation hook for self excluding
 * POST /auth/user/self-exclude
 * @returns {Object} - react-query mutation object
 */
export const useSelfExclude = () => {
    return useMutation({
        mutationFn: updateRGTimeout,
    });
};
