import { TrustlyEstablishData } from '../../types';

export const widget = (accessId: string, data: TrustlyEstablishData) => `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="Content-Security-Policy" content="default-src 'self' https://sandbox.trustly.one; script-src 'self' https://sandbox.trustly.one 'unsafe-inline'; style-src 'self' 'unsafe-inline';">
  <title>Trustly</title>
  <script src="https://sandbox.trustly.one/start/scripts/trustly.js?accessId=${accessId}" type="text/javascript"></script>
</head>
<body>
  <div id="widget"></div>
  <script>
    var establishData = ${JSON.stringify(data)};
    var TrustlyOptions = {
      closeButton: false,
      dragAndDrop: true,
      widgetContainerId: "widget",
    };

    const TrustlyWidgetBankSelected = (data) => {
      return false;
    }

    Trustly.selectBankWidget(establishData, TrustlyOptions, TrustlyWidgetBankSelected);

  </script>
</body>
</html>`;

export const lightbox = async (accessId: string, data: TrustlyEstablishData) => {
    if (!data) {
        return;
    }

    return `
    <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="Content-Security-Policy" content="default-src 'self' https://sandbox.trustly.one; script-src 'self' https://sandbox.trustly.one 'unsafe-inline'; style-src 'self' 'unsafe-inline';">
        <title>Trustly</title>
        <script src="https://sandbox.trustly.one/start/scripts/trustly.js?accessId=${accessId}" type="text/javascript"></script>
      </head>
      <body>
        <div id="widget"></div>
        <script>
          window.onerror = function(msg, url, line) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              type: 'error',
              message: msg,
              url: url,
              line: line
            }));
            return true;
          };
          var establishData = ${JSON.stringify(data)};
          var TrustlyOptions = {
            closeButton: false,
            dragAndDrop: true,
            widgetContainerId: "widget",
            onError: function(error) {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'trustlyError',
                error: error
              }));
            }
          };

          Trustly.establish(establishData, TrustlyOptions);

        </script>
      </body>
    </html>
  `;
};
