import React from 'react';
import { StyleSheet, View } from 'react-native';

import { PlayerInfoFragment, PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import LossIcon from '@/assets/icons/loss';
import StrikedThroughDotLarge from '@/assets/icons/striked-through-dot-large';
import WonIcon from '@/assets/icons/won';
import { LineSeparator } from '@/components/LineSeparator';
import { Progressbar } from '@/components/ProgressBar';
import { Box, Row } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { PickProjectionsIcons } from '@/feature/entries-pickem/components/PickProjectionsIcons';
import { PlayerDescription, ProjectionDescription } from '@/feature/lobby/components/PlayerDetails';
import { designSystem } from '@/styles/styles';
import { EventStatus, Outcome, ProjectionType, Result } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { gameUtils } from '@/utils/games';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

const styles = StyleSheet.create({
    progressCircle: {
        borderRadius: 100,
        width: 20,
        height: 20,
        borderWidth: 2,
        borderColor: designSystem.colors.gray5,
    },
    card: {
        paddingHorizontal: 16,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: designSystem.colors.gray6,
    },
    lastCard: {
        paddingBottom: 16,
        borderBottomWidth: 1,
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
        marginBottom: 16,
        borderCurve: 'continuous',
    },

    firstCard: {
        paddingTop: 16,
    },

    lineSeparator: {
        backgroundColor: designSystem.colors.gray6,
        width: '100%',
    },

    entryInformationContainer: {
        gap: 3.5,
    },

    detailsContainer: {
        gap: 4.5,
    },
    singleProjectionIcon: {
        marginRight: 0,
    },
    regularArrow: {
        marginLeft: 0,
    },
    nonRegularArrow: {
        marginLeft: -3,
    },
});

type Props = {
    event: EventInfo;
    player: PlayerInfoFragment;
    outcome: Outcome;
    teamId?: string;
    eventStatus: EventStatus;
    currentValue?: number;
    result?: Result;
    projection: PlayerProjectionFragment;
    isLast: boolean;
    isFirst: boolean;
};

export const EntryPickPlayerCard = ({
    event,
    player,
    outcome,
    teamId,
    eventStatus,
    currentValue,
    result,
    projection,
    isLast,
    isFirst,
}: Props) => {
    const isVoided = result === Result.Void;
    const hasStarted = eventStatus === EventStatus.InProgress;
    const hasEnded = gameUtils.hasEnded(eventStatus);

    const team = eventUtils.getTeamById(event, teamId);

    const { arcText, teamLogo } = getPlayerArcDetails(player, event?.league, team);
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(event?.league),
        defaultZustandCompareFunction
    );
    const playerNumber = getPlayerJerseyNumber(event?.league, player?.jerseyNumber);

    const playerImageOpacity = isVoided ? 0.4 : 1;

    const max =
        projection.type !== ProjectionType.Regular && projection.nonRegularValue
            ? projection.nonRegularValue
            : projection.value;
    const isProjectionRegular = projection.type === ProjectionType.Regular;

    return (
        <View style={[styles.card, isLast ? styles.lastCard : undefined, isFirst ? styles.firstCard : undefined]}>
            <Row alignItems="center" gap="s16">
                <PlayerProfileImage
                    playerImageUrl={player.icon}
                    teamImageUrl={teamLogo ?? leagueIcon}
                    teamColor={team?.color ?? leagueColor}
                    teamSecondaryColor={team?.secondaryColor}
                    arcText={arcText}
                    style={{ opacity: playerImageOpacity }}
                    playerNumber={playerNumber}
                />
                <Box style={styles.entryInformationContainer} flex={1}>
                    <Row gap="s8" alignItems="center">
                        <Box style={styles.detailsContainer} flex={1}>
                            <PlayerDescription
                                player={player}
                                teamName={team?.name || ''}
                                isVoided={isVoided}
                                event={event}
                            />
                            <Row gap="s6" alignItems="center">
                                <PickProjectionsIcons
                                    isVoided={isVoided}
                                    projection={projection}
                                    outcome={outcome}
                                    singleProjectionStyle={styles.singleProjectionIcon}
                                    arrowStyles={isProjectionRegular ? styles.regularArrow : styles.nonRegularArrow}
                                />
                                <Box flex={1}>
                                    <ProjectionDescription selectedProjection={projection} isVoided={isVoided} />
                                </Box>
                            </Row>
                        </Box>
                        <Box width={24} alignItems={'center'}>
                            {isVoided ? (
                                <StrikedThroughDotLarge />
                            ) : result === 'WIN' ? (
                                <WonIcon />
                            ) : result === 'LOSS' ? (
                                <LossIcon />
                            ) : (
                                <View style={styles.progressCircle} />
                            )}
                        </Box>
                    </Row>
                    <Box height={20} justifyContent="center">
                        <Progressbar
                            value={currentValue}
                            max={max}
                            result={result}
                            outcome={outcome}
                            loading={
                                !isVoided &&
                                (hasStarted || hasEnded) &&
                                (currentValue === null || currentValue === undefined)
                            }
                        />
                    </Box>
                </Box>
            </Row>
            {!isLast ? (
                <Row gap="s16" height={16} marginVertical="s8" alignItems="center">
                    <Box flex={1}>
                        <LineSeparator style={styles.lineSeparator} />
                    </Box>
                </Row>
            ) : null}
        </View>
    );
};
