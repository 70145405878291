import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Checkbox } from '@/components/Checkbox';
import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { useSbkBetSlipStore } from '../../hooks/use-sbk-betslip-store';
import { BetTypes } from '../../types';
import { ComboBetPlacedCard } from './ComboBetPlacedCard';
import { SingleBetPlacedCard } from './SingleBetPlacedCard';

const styles = StyleSheet.create({
    scrollContainer: {
        paddingBottom: 0,
    },
    sheetContainer: { marginTop: -12 },
});

const BOTTOM_PADDING = 16;

export const BetPlacedSheet = () => {
    const { height } = useWindowDimensions();
    const [screenModalHeight, setScreenModalHeight] = useState(0);
    const headerHeight = useRef(0);
    const safeInsets = useSafeAreaInsets();
    const { t } = useTranslation(['betslip_sbk', 'bets', 'wallet']);

    // Screen size - safe insets - close button height
    const CLOSE_BUTTON_HEIGHT = 150;
    const maxContainerHeight = height - safeInsets.top - CLOSE_BUTTON_HEIGHT;

    const [successfulPlacedBets, keepSelectionsInBetSlip] = useSbkBetSlipStore(state => {
        const betSubmittedState = state.submittedState!;
        const singles = betSubmittedState.singlesBets;
        const combo = betSubmittedState.comboBet?.bet;
        const allBets = combo ? [combo, ...singles] : singles;
        return [allBets, state.keepSelectionsInBetSlip];
    });

    const toggleKeepSelectionsInBetSlip = useSbkBetSlipStore(state => state.actions.toggleKeepSelectionsInBetSlip);

    return (
        <Box
            style={styles.sheetContainer}
            onLayout={event => setScreenModalHeight(event.nativeEvent.layout.height)}
            maxHeight={maxContainerHeight}
        >
            <Box
                onLayout={event => {
                    headerHeight.current = event.nativeEvent.layout.height - BOTTOM_PADDING;
                }}
                marginBottom="s16"
            >
                <Text variant="headlineSmall" color="gray1" textAlign="center" testID="betSummaryTitle">
                    {t('bet_placed')}
                </Text>
            </Box>
            <FlatList
                data={successfulPlacedBets}
                style={[styles.scrollContainer]}
                showsVerticalScrollIndicator={showScrollIndicator}
                scrollEnabled={screenModalHeight >= maxContainerHeight}
                keyExtractor={(_, idx) => idx.toString()}
                renderItem={({ item }) => {
                    return (
                        <Box paddingVertical="s8">
                            {item.betType === BetTypes.Combo ? (
                                <ComboBetPlacedCard submittedBet={item} />
                            ) : (
                                <SingleBetPlacedCard submittedBet={item} />
                            )}
                        </Box>
                    );
                }}
            />
            <Row justifyContent={'space-between'} alignItems={'center'} paddingTop={'s24'}>
                <Text variant={'bodyMedium'} color="gray2" textAlign="center">
                    {t('keep_selections')}
                </Text>
                <TouchableBox
                    flexDirection="row"
                    alignItems="center"
                    alignSelf="stretch"
                    onPress={toggleKeepSelectionsInBetSlip}
                    testID="checkBox"
                    hitSlop={20}
                >
                    <Checkbox value={keepSelectionsInBetSlip} setValue={() => {}} />
                </TouchableBox>
            </Row>
        </Box>
    );
};
