import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import { useEventsInfoQuery } from '@/api/events/query.generated';
import DownIcon from '@/assets/icons/down';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { usePicksList } from '@/feature/betslip-pickem/screens/PlayersPicksListProvider';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { SEPARATOR_HEIGHT, common } from '@/styles/styles';
import { getNonRegularProjections } from '@/utils/getProjectionsTypes';

import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { sortBetslipItemsByEdgeCombos } from '../utils/betslip-edge-combos';
import { getWinningPicksAndToWin } from '../utils/betslip-utils';
import { PlayerPickSlipCard } from './PlayerPickSlipCard';
import { PlayersIconsGroup } from './PlayersIconsGroup';

export const LineupSummary = ({ shouldOpenLineupModal }: { shouldOpenLineupModal?: boolean }) => {
    const betslip = useBetslipStore(store => store.betslip);
    const { edgeCombos } = useBetslipData();
    const sortedBetslip = sortBetslipItemsByEdgeCombos(betslip, edgeCombos);

    const { dynamicMultipliers, dynamicBoostedMultiplier, perfectActiveMultiplier, perfectModeDisabled } =
        useBetslipData();
    const playersNames = sortedBetslip.map(pick => pick.player.lastName || pick.player.firstName).join(', ');
    const playerIconsDetails = sortedBetslip.map(pick => ({
        eventId: pick.eventId,
        player: pick.player,
    }));
    const { hasBoostedProjections } = getNonRegularProjections(sortedBetslip.map(it => it.projection));
    const { showPicksListModal } = usePicksList();

    const { highestMultiplier } = getWinningPicksAndToWin(dynamicMultipliers.DYNAMIC, dynamicBoostedMultiplier);
    // if we have perfect mode disabled, we should use the highest multiplier from dynamic mode and vice versa
    const maxMultiplier = perfectModeDisabled ? highestMultiplier : perfectActiveMultiplier;

    useEffect(() => {
        if (shouldOpenLineupModal) {
            showPicksListModal();
        }
    }, [shouldOpenLineupModal, showPicksListModal]);

    const handlePress = () => {
        showPicksListModal();
        // TODO: find a way to track modals as they are rendered before calling present()
        BetrAnalytics.trackScreenView('Lineup Summary');
    };

    // get the data for the first item in the betslip if there is only one item
    const [{ data: firstEventInList }] = useEventsInfoQuery({
        variables: { ids: sortedBetslip[0]?.eventId },
        requestPolicy: 'cache-and-network',
        pause: sortedBetslip.length !== 1,
    });

    const { makeSelection, openPlayerPickModal, removeSelection } = usePickSelection();

    const { t } = useTranslation(['betslip_pickem']);

    return (
        <TouchableOpacity
            onPress={handlePress}
            activeOpacity={0.8}
            testID="lineupSummary"
            accessible={false}
            disabled={sortedBetslip.length === 1}
        >
            <Text variant={'headlineMedium'}>{t('lineup_summary')}</Text>
            {sortedBetslip.length === 1 ? (
                <View style={common.full}>
                    <SizedBox value={8} />
                    <PlayerPickSlipCard
                        {...sortedBetslip[0]}
                        presentation={'default'}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                        removeSelection={removeSelection}
                        eventDetails={firstEventInList?.getEventsByIdsV2[0]}
                    />
                </View>
            ) : (
                <Row mb={'s24'} py={'s16'} borderBottomColor={'gray5'} borderBottomWidth={SEPARATOR_HEIGHT}>
                    <PlayersIconsGroup
                        playerIconsDetails={playerIconsDetails}
                        hasBoostedProjections={hasBoostedProjections}
                    />
                    <Box flex={1} mx={'s16'} justifyContent={'center'}>
                        <Text variant={'titleMedium'} color={hasBoostedProjections ? 'boosted' : 'gray1'}>
                            {t('count_picks_to_win_up_to_multiplier', {
                                count: betslip.length,
                                multiplier: maxMultiplier,
                            })}
                        </Text>
                        <Text
                            variant={'bodySmall'}
                            color={'gray2'}
                            numberOfLines={1}
                            testID="lineupSummaryPlayersNames"
                        >
                            {playersNames}
                        </Text>
                    </Box>
                    <Box justifyContent={'center'}>
                        <DownIcon />
                    </Box>
                </Row>
            )}
        </TouchableOpacity>
    );
};
