import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Platform, StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { NotifyOnSettledEntryOrPickDocument, useGetEntryDetailsQuery } from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import { useEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { CloseIcon } from '@/assets/icons/close';
import Gradient from '@/assets/icons/gradient';
import ShareIcon from '@/assets/icons/share-icon';
import { KeyboardAvoidingViewNoAnimation } from '@/components/KeyboardAvoidingViewNoAnimation';
import { LineSeparator } from '@/components/LineSeparator';
import { Loading } from '@/components/Loading';
import { PickProgressDots } from '@/components/PickProgressDots';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Box, Row } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { DynamicPayoutSheet } from '@/feature/betslip-pickem/components/DynamicPayoutSheet';
import { playersHaveEdgeCombo } from '@/feature/betslip-pickem/utils/betslip-edge-combos';
import { CancelEntryButton } from '@/feature/cancel-entry/components/CancelEntryButton';
import { EntryPickPlayerCard } from '@/feature/entries-pickem/components/EntryPickPlayerCard';
import { EntrySectionEventHeader } from '@/feature/entries-pickem/components/EntrySectionEventHeader';
import { getRadialGradientStyle, gradientWidth } from '@/feature/entry-share/utils/get-radial-gradient-style';
import { ViewP2pLeaderboardsButton } from '@/feature/p2p-leaderboards/components/ViewP2pLeaderboardsButton';
import {
    CellRendererComponent,
    MaxWidthWrapper,
    webListContentContainerStyle,
    webMaxWidthStyle,
} from '@/feature/responsive-design/WebComponents';
import { useResumeEffect } from '@/hooks/use-resume';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem, withOpacity } from '@/styles/styles';
import { GameType, ProjectionType, Result } from '@/types/api.generated';
import { isWeb, nativeDriverValue, showScrollIndicator } from '@/utils/constants-platform-specific';
import { gameUtils } from '@/utils/games';
import { getEntryPayout } from '@/utils/getEntryPayout';
import { nflFullSeason } from '@/utils/league';
import { groupPicksByEvents } from '@/utils/map-to-events';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useSubscription } from 'urql';

import { EntryFooter } from '../components/EntryFooter';
import { EntryHeader } from '../components/EntryHeader';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'EntryScreen'>;

const styles = StyleSheet.create({
    gradient: { height: 216, width: gradientWidth, position: 'absolute', top: -35 },
    contentContainer: {
        position: 'absolute',
        width: '100%',
    },
});

export const EntryScreen = ({ route: { params } }: ScreenProps) => {
    const scrollRef = useRef(new Animated.Value(0)).current;
    const [entry, setLocalEntry] = useState<EntryDetails>();
    const [eventsMap, setEventsMap] = useState<Record<string, EventInfo>>({});
    const [{ data, fetching }, executeGetEntryDetails] = useGetEntryDetailsQuery({
        variables: { id: params.id },
        pause: true,
    });

    const dynamicPayoutSheet = useRef<BottomSheetModal>(null);
    const showLeaderboards = entry?.gameType === GameType.P2P && !!entry.id;

    const [hideCancelButton, setHideCancelButton] = useState(false);

    const showCancelButton = entry?.canBeCanceled && !hideCancelButton;

    const showEntryActions = showCancelButton || showLeaderboards;

    const navigation = useNavigation();
    const [] = useSubscription(
        { query: NotifyOnSettledEntryOrPickDocument, variables: { id: entry?.id } },
        (_, next) => {
            setLocalEntry(val => {
                const nextPayload = next?.notifyOnSettledEntryOrPick;
                if (nextPayload) {
                    return nextPayload;
                }

                return val;
            });
        }
    );

    const picks = entry?.picks;
    const hasFullSeasonPicks = picks?.some(it => nflFullSeason(it.league));

    const sections = groupPicksByEvents(picks ?? [], entry?.edgeCombos ?? []);
    const uniqueEventIds = Array.from(new Set(sections?.map(section => section.eventId) ?? []));

    const [{ data: eventsInfoData }, executeEventsInfoQuery] = useEventsInfoQuery({
        requestPolicy: 'network-only',
        variables: {
            ids: uniqueEventIds,
        },
        pause: true,
    });

    const entryPayout = useMemo(() => getEntryPayout({ picks: entry?.picks }), [entry]);

    const isRefunded = useMemo(() => entry?.result === 'VOID', [entry?.result]);

    const dynamicMultipliers = useMemo(() => {
        // show the multipliers for the non voided number of picks
        const nonVoidedCount = entry?.picks.filter(pick => pick.result !== Result.Void).length ?? 0;
        const initialMultipliers = entry?.dynamicMultipliers?.filter(it => it.numberOfPicks === entry.picks.length);
        const multipliersForNonVoided = entry?.dynamicMultipliers?.filter(it => it.numberOfPicks === nonVoidedCount);
        // if there are no multipliers for the non voided number of picks, show the initial multipliers
        return !!multipliersForNonVoided && multipliersForNonVoided.length > 0
            ? multipliersForNonVoided
            : initialMultipliers;
    }, [entry]);

    const boostedPicks = picks?.filter(pick => pick.projection.type === ProjectionType.Boosted);
    const nonVoidedPickCount = picks?.filter(pick => pick.result !== Result.Void).length ?? 0;
    const nonVoidedBoostedPicksCount = boostedPicks?.filter(pick => pick.result !== Result.Void).length ?? 0;
    const dynamicBoostedMultiplier = entry?.boostedMultipliers?.find(
        it => it.numberOfPicks === nonVoidedPickCount && it.numberOfBoostedPicks === nonVoidedBoostedPicksCount
    );
    const safeInsets = useSafeAreaInsets();
    const firstLiveEventId = eventsInfoData?.getEventsByIdsV2?.find(event => {
        return gameUtils.isLive(event);
    })?.id;

    useEffect(() => {
        if (data?.getEntry) {
            setLocalEntry(data?.getEntry);
        }
    }, [data?.getEntry]);

    useEffect(() => {
        if (!entry) {
            return;
        }
        executeEventsInfoQuery();
    }, [entry, executeEventsInfoQuery]);

    useEffect(() => {
        if (!eventsInfoData?.getEventsByIdsV2?.length) {
            return;
        }

        setEventsMap(eventsInfoData.getEventsByIdsV2.reduce((acc, event) => ({ ...acc, [event.id]: event }), {}));
    }, [eventsInfoData?.getEventsByIdsV2]);

    const reload = useCallback(() => {
        executeGetEntryDetails({ requestPolicy: 'network-only' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResumeEffect(reload);

    const isLoading = fetching || !entry || !Array.isArray(picks);

    const { width } = useWindowDimensions();
    const gradientStyle = getRadialGradientStyle(width);

    const { height: windowHeight } = useWindowDimensions();

    const openPayoutSheet = useCallback(() => {
        dynamicPayoutSheet.current?.present();
    }, []);

    const gradientOpacity = scrollRef.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 0],
        extrapolate: 'clamp',
    });

    return (
        <Screen>
            {entry?.result === Result.Win ? (
                <Animated.View style={[styles.gradient, isWeb && gradientStyle, { opacity: gradientOpacity }]}>
                    <Box alignItems={isWeb ? 'center' : 'flex-start'}>
                        <Gradient color={withOpacity(designSystem.colors.green, 0.4)} style={[gradientStyle]} />
                    </Box>
                </Animated.View>
            ) : null}
            <Box height={isWeb ? windowHeight : '100%'}>
                <KeyboardAvoidingViewNoAnimation
                    behavior={Platform.OS === 'ios' ? 'padding' : undefined}
                    style={common.flex}
                    keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
                >
                    <SizedBox value={10} />
                    <Row px={'s16'} py={'s18'} justifyContent={'space-between'} alignItems={'center'}>
                        <TouchableOpacity hitSlop={20} activeOpacity={0.8} onPress={() => navigation.goBack()}>
                            <CloseIcon />
                        </TouchableOpacity>
                        <PickProgressDots picks={picks!} light={false} />
                        <TouchableOpacity
                            onPress={() => {
                                BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_ENTRY, { screen: 'entry_details' });
                                trackRUMAction('share-entry');
                                navigation.navigate('EntryShare', {
                                    id: entry?.id,
                                });
                            }}
                        >
                            <ShareIcon />
                        </TouchableOpacity>
                    </Row>

                    {fetching ? (
                        <View style={[common.paddingVertical]}>
                            <Loading />
                        </View>
                    ) : null}
                    {
                        // there's a bug in section list where it doesn't render the next page when scrolling
                        // not rendering the list when there's no data somehow fixes it: https://github.com/facebook/react-native/issues/39421
                        sections?.length > 0 ? (
                            <Animated.SectionList
                                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollRef } } }], {
                                    useNativeDriver: nativeDriverValue,
                                })}
                                style={common.paddingHorizontal}
                                contentContainerStyle={[
                                    // this padding is needed to make sure the last item is not hidden by the bottom cancel button tray
                                    // we need to take into account: safe area, bottom cancel padding (2*16), cancel button height (44)
                                    showCancelButton && { paddingBottom: safeInsets.bottom + 2 * 16 + 44 },
                                    webListContentContainerStyle,
                                ]}
                                CellRendererComponent={CellRendererComponent}
                                sections={sections}
                                maxToRenderPerBatch={3}
                                initialNumToRender={3}
                                stickySectionHeadersEnabled={false}
                                showsVerticalScrollIndicator={showScrollIndicator}
                                ListHeaderComponent={
                                    <EntryHeader
                                        entry={entry}
                                        picks={picks}
                                        entryPayout={entryPayout}
                                        isLoading={isLoading}
                                        isRefunded={isRefunded}
                                        openPayoutSheet={openPayoutSheet}
                                    />
                                }
                                ListFooterComponent={
                                    <EntryFooter
                                        entry={entry}
                                        hasFullSeasonPicks={hasFullSeasonPicks}
                                        events={eventsInfoData?.getEventsByIdsV2 ?? []}
                                        isLeaderboardButtonDisplayed={showLeaderboards}
                                    />
                                }
                                ListHeaderComponentStyle={webMaxWidthStyle}
                                ListFooterComponentStyle={webMaxWidthStyle}
                                renderSectionHeader={({ section }) => {
                                    return (
                                        <EntrySectionEventHeader
                                            event={eventsMap[section.eventId]}
                                            status={entry?.status}
                                            showGameTrackerTooltip={section.eventId === firstLiveEventId}
                                        />
                                    );
                                }}
                                renderItem={({ item, index, section }) => {
                                    const isFirst = index === 0;
                                    const isLast = index + 1 === section.data.length;
                                    const nextPlayer = section.data[index + 1];
                                    const isEdgeCombo = playersHaveEdgeCombo(
                                        entry?.edgeCombos ?? [],
                                        item.player,
                                        nextPlayer?.player
                                    );
                                    let edgeComboData;
                                    if (isEdgeCombo) {
                                        edgeComboData = [
                                            { pick: item, event: eventsMap[item.eventId] },
                                            { pick: nextPlayer, event: eventsMap[nextPlayer.eventId] },
                                        ];
                                    }

                                    return (
                                        <EntryPickPlayerCard
                                            key={item.teamId}
                                            event={eventsMap[item.eventId]}
                                            isLast={isLast}
                                            isFirst={isFirst}
                                            {...item}
                                            isEdgeCombo={isEdgeCombo}
                                            edgeComboData={edgeComboData}
                                        />
                                    );
                                }}
                            />
                        ) : null
                    }
                </KeyboardAvoidingViewNoAnimation>
                {showEntryActions ? (
                    <MaxWidthWrapper position="absolute" width={'100%'} bottom={0} pointerEvents="box-none">
                        {showLeaderboards ? (
                            <>
                                <ViewP2pLeaderboardsButton id={entry?.id} />
                            </>
                        ) : null}
                        <Box
                            style={{ paddingBottom: safeInsets.bottom }}
                            pointerEvents="box-none"
                            backgroundColor={showCancelButton ? 'gray8' : 'transparent'}
                        >
                            {showCancelButton ? (
                                <>
                                    <LineSeparator />
                                    <Box padding="s16" pointerEvents="box-none">
                                        <CancelEntryButton
                                            entry={entry}
                                            setHideCancelButton={setHideCancelButton}
                                            events={eventsInfoData?.getEventsByIdsV2 ?? []}
                                        />
                                    </Box>
                                </>
                            ) : null}
                        </Box>
                    </MaxWidthWrapper>
                ) : null}
                <DynamicPayoutSheet
                    payoutSheetRef={dynamicPayoutSheet}
                    entryAmount={entry?.amount ?? 0}
                    dynamicMultipliers={dynamicMultipliers ?? []}
                    dynamicBoostedMultiplier={dynamicBoostedMultiplier}
                />
            </Box>
        </Screen>
    );
};
