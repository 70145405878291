import React, { memo, useMemo } from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';

import { ProductSettings, useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { OldPlayerImagePlaceholderContainer, OldPlayerProfileImage } from './OldPlayerProfileImage';
import {
    PlayerImagePlaceholderContainerWithGradient,
    PlayerProfileImageWithGradient,
} from './PlayerProfileImageWithGradient';
import { Rectangle } from './types';

type Props = {
    playerImageUrl?: string;
    arcText?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    onLoadImage?: () => void;
    imageVariant?: 'small' | 'big';
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    teamSecondaryColor?: string;
    bgColor?: 'gray6' | 'gray5';
};

type PlaceholderProps = {
    onLoad?: () => void;
    placeholderSize?: Rectangle;
    masked?: boolean;
    marginTop?: number;
};

export const PlayerImagePlaceholderContainer = memo((props: PlaceholderProps) => {
    const enableGradientPlayerProfileImage = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_gradient_player_profile_image
    );

    // We do not want the reference to change on re-renders
    const Component = useMemo(
        () =>
            enableGradientPlayerProfileImage
                ? PlayerImagePlaceholderContainerWithGradient
                : OldPlayerImagePlaceholderContainer,
        [enableGradientPlayerProfileImage]
    );

    return <Component {...props} />;
});

export const PlayerProfileImage = (props: Props) => {
    const enableGradientSettingKey = Platform.select<keyof ProductSettings>({
        android: 'enable_gradient_player_profile_image_android',
        ios: 'enable_gradient_player_profile_image_ios',
        default: 'enable_gradient_player_profile_image',
    });

    const enableGradientPlayerProfileImage = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.[enableGradientSettingKey]
    );

    // We do not want the reference to change on re-renders
    const Component = useMemo(
        () => (enableGradientPlayerProfileImage ? PlayerProfileImageWithGradient : OldPlayerProfileImage),
        [enableGradientPlayerProfileImage]
    );

    return <Component {...props} />;
};
