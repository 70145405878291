import { NativeModules } from 'react-native';

import { APP_ID, BUNDLE_IDENTIFIER } from '@/utils/constants';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

import { firebaseConfigProd, firebaseConfigStaging } from './firebaseWebConfig';

const STORYBOOK_ENABLED = false;

const STAGING: string = 'STAGING';
const PROD: string = 'PRODUCTION';
const DEV: string = 'DEV';
const GLI: string = 'GLI';
const PLAYGROUND: string = 'PLAYGROUND';

const Configs = {
    [STAGING]: STAGING,
    [PROD]: PROD,
    [DEV]: DEV,
    [GLI]: GLI,
    [PLAYGROUND]: PLAYGROUND,
};

type ConfigList = Record<keyof typeof Configs, string>;

const defineConfig = (prod: string, staging?: string, dev?: string, gli?: string, playground?: string): ConfigList => {
    return {
        [PROD]: prod,
        [STAGING]: staging ?? prod,
        [DEV]: dev ?? staging ?? prod,
        [GLI]: gli ?? staging ?? prod,
        [PLAYGROUND]: playground ?? dev ?? staging ?? prod,
    };
};

const CONFIGS = {
    DECODE_PAYLOAD_URL: defineConfig(
        'https://geo-license.betr.app/betr/US-FANTASY',
        'https://rc-geo-license.betr.app/betr/US-FANTASY'
    ),
    LICENSE_SERVER_URL: defineConfig(
        'https://api.fantasy.betr.app/api/geocomply/license',
        'https://api.acc.betrdev.com/api/geocomply/license',
        'https://api.fantasy-dev.betr.app/api/geocomply/license',
        'https://api.gli.betrdev.com/api/geocomply/license',
        'https://api.fantasy-playground.betr.app/api/geocomply/license'
    ),
    CHAMELON_API_URL: defineConfig(
        'https://api.betr.app/api/v3',
        'https://rc-api.betr.app/api/v3',
        'https://stage3-api.betr.app/api/v3',
        'https://gli33-api.betr.app/api/v3'
    ),
    KEYCLOAK_CLIENT_ID: defineConfig('betr-rn', 'betr-rn', 'betr-rn', 'betr-rn'),
    KEYCLOAK_AUTH_URL: defineConfig(
        'https://account.betr.app/realms/betr/protocol/openid-connect/auth',
        'https://account-rc.betr.app/realms/jeremy-sms-realm/protocol/openid-connect/auth',
        'https://stage-account.betr.app/realms/jeremy-sms-realm/protocol/openid-connect/auth',
        'https://account-rc.betr.app/realms/gli33/protocol/openid-connect/auth'
    ),
    KEYCLOAK_REGISTER_URL: defineConfig(
        'https://account.betr.app/realms/betr/protocol/openid-connect/registrations',
        'https://account-rc.betr.app/realms/jeremy-sms-realm/protocol/openid-connect/registrations',
        'https://stage-account.betr.app/realms/jeremy-sms-realm/protocol/openid-connect/registrations',
        'https://account-rc.betr.app/realms/gli33/protocol/openid-connect/registrations'
    ),
    KEYCLOAK_AUTH_REDIRECT_URL: defineConfig(
        'https://account.betr.app/realms/betr/.well-known/openid-configuration',
        'https://account-rc.betr.app/realms/jeremy-sms-realm/.well-known/openid-configuration',
        'https://stage-account.betr.app/realms/jeremy-sms-realm/.well-known/openid-configuration',
        'https://account-rc.betr.app/realms/gli33/.well-known/openid-configuration'
    ),
    KEYCLOAK_AUTH_AUDIENCE_URL: defineConfig(
        'https://account.betr.app/realms/betr/api/v2',
        'https://account-rc.betr.app/realms/jeremy-sms-realm/api/v2',
        'https://stage-account.betr.app/realms/jeremy-sms-realm/api/v2',
        'https://account-rc.betr.app/realms/gli33/api/v2'
    ),
    FEED_URL: defineConfig(
        'https://api.fantasy.betr.app/graphql',
        'https://api.acc.betrdev.com/graphql',
        'https://api.fantasy-dev.betr.app/graphql',
        'https://api.acc.betrdev.com/graphql',
        'https://api.fantasy-playground.betr.app/graphql'
    ),
    SUBSCRIPTION_URL: defineConfig(
        'https://api.fantasy.betr.app/subscriptions',
        'wss://api.acc.betrdev.com/subscriptions',
        'wss://api.fantasy-dev.betr.app/subscriptions',
        'wss://api.acc.betrdev.com/subscriptions',
        'wss://api.fantasy-playground.betr.app/subscriptions'
    ),
    BONUS_ENGINE_URL: defineConfig(
        'https://api.bonus.marketing.betr.app/api',
        'https://api.bonus.marketing-acc.betr.app/api',
        'https://api.bonus.marketing-dev.betr.app/api',
        'https://api.bonus.marketing-acc.betr.app/api',
        'https://api.bonus.marketing-playground.betr.app/api'
    ),
    PRISMIC_URL: defineConfig('https://pitchtech.cdn.prismic.io/api/v2'),
    PROMO_RAF_PAGE_URL: defineConfig('https://promos.betr.app', 'https://promos-dev.betr.vercel.app'),
    BETR_CDN_URL: defineConfig('https://cdn.betr.app/cdn-cgi'),
    SUPPORT_URL: defineConfig('https://help.betr.app'),
    PAYSAFE_ENV: defineConfig('LIVE', 'TEST'),
    GOOGLE_PLACES_API_BASE_URL: defineConfig('https://maps.googleapis.com/maps/api'),
    PROXY_SERVER_URL: defineConfig('https://proxy-server-betr.vercel.app/api'),
    PAYSAFE_SDK: defineConfig(
        'https://hosted.paysafe.com/js/v1/latest/paysafe.min.js',
        'https://hosted.test.paysafe.com/js/v1/latest/paysafe.min.js'
    ),
    ALLOWED_STATES: defineConfig(
        'https://support.betr.app/hc/en-us/articles/15156945128852-Whats-states-allow-Betr-Picks'
    ),
    HOW_TO_UPDATE_DEBIT_CARD_URL: defineConfig(
        'https://support.betr.app/hc/en-us/articles/26411297665172-How-do-I-update-my-expired-debit-card'
    ),
    ACS_URL: defineConfig(
        'https://acs.betr.app/api/prod',
        'https://acs-dev-betr.vercel.app/api/rc',
        'https://acs-dev-betr.vercel.app/api/dev',
        'https://acs-dev-betr.vercel.app/api/gli'
    ),
    APP_LINKS_URL: defineConfig('https://l.betr.app', 'https://l.betr.vercel.app'),
    APP_STORE_URL: defineConfig(`itms-apps://apps.apple.com/us/app/betr-fantasy-sportsbook/id${APP_ID}`),
    GOOGLE_PLAY_STORE_URL: defineConfig(`https://play.google.com/store/apps/details?id=${BUNDLE_IDENTIFIER}&gl=US`),
    BET_GENIUS_GAME_TRACKER_URL: defineConfig('https://gsm-widgets.betstream.betgenius.com/multisportgametracker'),
    TRUSTLY_WEBHOOK_URL: defineConfig(
        'https://api.betr.app/trustlyCallback',
        'https://rc-api.betr.app/trustlyCallback',
        'https://stage3-api.betr.app/trustlyCallback',
        'https://api.betr.app/trustlyCallback'
    ),
} as const;

const CONSTANTS_VALUES = {
    APP_CONFIG_UID: defineConfig('app_config', 'app_config_rc'),
    GOOGLE_PLACES_API_KEY: defineConfig('AIzaSyCjglBktSZLC8XPxFUyIfZe-F3mk1Vj8kg'), //This is the production API key
    PHRASE_OTA_DISTRIBUTION_ID: defineConfig('c89d7820482a24f7f5f049fef5bfe353'),
    PHRASE_OTA_DISTRIBUTION_ENV_ID: defineConfig(
        'gkCVgNoMuQo4AIdpeqTU1oaXlMuKaohUWptJjJ8b8Yc',
        'wFSvCmOJwwushe9a6zhBV0ClaZb2mQfYmJ5e-ZfpyN4'
    ),
    ABLY_API_KEY: defineConfig(
        'cANxcg.u_eqyw:Q27wB1yJ77rwNcNaL-Xp4pSi4ExmmXb7ACpZyaHZC3A',
        'Nz4EsQ.pjHmnw:NcVrBTeWPwCfKD5FW3FMQ9ru_uB-TfpZeBe5GMFQNRQ',
        'W1IKSQ.QMTe2A:FdzR4LHk07nPaj_nQKub9y7o7ZjtCaSaOxWbBL7SjLs',
        'Nz4EsQ.pjHmnw:NcVrBTeWPwCfKD5FW3FMQ9ru_uB-TfpZeBe5GMFQNRQ'
    ),
    IOS_APP_ID: defineConfig('1635215598', '6448993369'),
    ONE_TRUST_WEB_APP_ID: defineConfig('01920229-6f09-761b-b2df-30bd9b72b459', '01917141-32f7-7978-8755-6007e704fccc'),
    AF_WEB_APP_ID: defineConfig('0ec71bbd-d8e2-4207-82e2-2d6d4718d9f8', '273895b4-c53c-41e5-848b-300112791384'),
    BRAZE_WEB_API_KEY: defineConfig(
        'e45ac492-5b5b-42fa-9cf0-7e546c408df6',
        'ab5813ff-1109-478b-8baf-b3a99c120496',
        'ada33f79-d2bd-4130-b38b-2863c18ac55d'
    ),
} as const;

type ConfigKey = keyof typeof CONFIGS;
type ConstantKey = keyof typeof CONSTANTS_VALUES;

declare global {
    interface ProxyConstructor {
        new <TSource extends object, TTarget extends object>(target: TSource, handler: ProxyHandler<TSource>): TTarget;
    }
}

// Default
let ENV: string | undefined;

const URLS: Record<keyof typeof CONFIGS, string> = new Proxy(CONFIGS, {
    get(target, prop: ConfigKey) {
        if (prop in target) {
            let targetEnv = ENV;
            if (!targetEnv) {
                logger.warn(`Calling URLS.${prop} before setEnv()! Fallback to PROD value !`);
                targetEnv = PROD;
            }
            return target[prop]?.[targetEnv];
        }
        return undefined;
    },
});

const CONSTANTS: Record<keyof typeof CONSTANTS_VALUES, string> = new Proxy(CONSTANTS_VALUES, {
    get(target, prop: ConstantKey) {
        if (prop in target) {
            let targetEnv = ENV;
            if (!targetEnv) {
                logger.warn(`Calling CONSTANTS.${prop} before setEnv()! Fallback to PROD value !`);
                targetEnv = PROD;
            }
            return target[prop]?.[targetEnv];
        }
        return undefined;
    },
});

const allowedValues = [PROD, STAGING, DEV, GLI, PLAYGROUND];

const setupEnv = () => {
    ENV = getEnv();
};

const getEnv = () => {
    if (isWeb) {
        /**
         * We treat the master branch as production environment
         * and any other branch as staging.
         * But keep in mind that this is not the same as production mode, which
         * is controlled by the NODE_ENV environment variable.
         */
        const hostname = window.location.hostname;

        switch (process.env.PUBLIC_ENVIRONMENT) {
            case 'production':
                return PROD;
            case 'staging':
                if (hostname.includes('app-dev.betr.app')) {
                    return DEV;
                } else if (hostname.includes('app-playground.betr.app')) {
                    return PLAYGROUND;
                } else {
                    return STAGING;
                }
            default:
                return STAGING;
        }
    }

    // Default to the build variant environment on native apps
    const env = NativeModules.InfoModule?.env;
    const parsed = typeof env === 'string' ? env.toUpperCase() : '';
    // Return the environment if valid, or default to STAGING
    return allowedValues.includes(parsed) ? parsed : STAGING;
};

/**
 * Generates the authentication redirect URI based on the platform and authentication flow.
 *
 * @param {boolean} [isWebRegister] - Optional flag indicating if the user is registering on the web.
 * @returns {string} The authentication redirect URI.
 */
const getAuthRedirectURI = (isWebRegister?: boolean) => {
    if (isWeb) {
        const baseUrl = window.location.origin + '/login';
        return isWebRegister ? `${baseUrl}?registering=true` : baseUrl;
    }
    return URLS.KEYCLOAK_AUTH_REDIRECT_URL;
};

const getFirebaseWebConfig = () => {
    ENV = getEnv();
    switch (ENV) {
        case PROD:
            return firebaseConfigProd;
        case STAGING:
        default:
            return firebaseConfigStaging;
    }
};

const getWebDomain = () => {
    ENV = getEnv();
    switch (ENV) {
        case PROD:
            return 'https://picks.betr.app';
        case STAGING:
        default:
            return 'https://app-rc.betr.app';
    }
};

// You can find App ID on the Intercom dashboard
// ref: https://developers.intercom.com/installing-intercom/web/installation#basic-javascript
const INTERCOM_APP_ID = 'ywtc0b5f';

// ref: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#step-2-initialize-the-sdk
const BRAZE_WEB_BASE_URL = 'sdk.iad-05.braze.com';

const TRUSTLY_CONFIGURATIONS = {
    ACCESS_ID: 'pjiUaUiLC4Mnx7jrrQHq',
    ACCESS_KEY: '80skPqIiWqLHpr447Ctq',
    MERCHANT_ID: '1287',
};

export {
    setupEnv,
    getEnv,
    getAuthRedirectURI,
    getFirebaseWebConfig,
    getWebDomain,
    STAGING,
    PROD,
    DEV,
    GLI,
    PLAYGROUND,
    URLS,
    CONSTANTS,
    ENV,
    STORYBOOK_ENABLED,
    INTERCOM_APP_ID,
    BRAZE_WEB_BASE_URL,
    TRUSTLY_CONFIGURATIONS,
};
