import { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import { useLinking } from '@/feature/entry-share/hooks/use-linking';
import { logger } from '@/utils/logging';

export const extractDeepLinkValue = (url?: string) => {
    const match = url?.match(/deep_link_value=([^&]+)/);
    return match ? match[1] : null;
};

export const isOneLink = (url: string) => {
    return url.includes('onelink.me');
};

const LOG_TAG = '[OneLink-AppsFlyer]';

/**
 * Custom hook for handling one-link navigation.
 * This hook handles deep link navigation based on the provided URL.
 * It uses the `useNavigation` hook from React Navigation to navigate to different screens.
 *
 * @returns An object containing the initial link and the current link.
 */
export const useOneLinkNavigation = () => {
    const navigation = useNavigation();

    const handleRedirectLink = useCallback(
        (url?: string) => {
            if (!!url && !isOneLink(url)) {
                return;
            }
            const deepLinkValue = extractDeepLinkValue(url);
            logger.info(LOG_TAG, `Handling redirect link: ${url}`);
            // Handle the deep link navigation
            if (deepLinkValue === 'deposit') {
                navigation.navigate('DepositScreen', { selectedAmount: '' });
            } else if (deepLinkValue === 'gaming_limits') {
                navigation.navigate('EntryScreen', { id: '' });
            } else if (deepLinkValue === 'event_sport') {
                navigation.navigate('FantasyPickSlip');
            } else {
                logger.info(LOG_TAG, `Link not handled: ${url}`);
            }
        },
        [navigation]
    );

    const handleInitialLink = useCallback(
        (url: string) => {
            logger.info(LOG_TAG, `Initial link ${url}`);
            handleRedirectLink(url);
        },
        [handleRedirectLink]
    );

    const handleNewLink = useCallback(
        (url: string) => {
            logger.info(LOG_TAG, `New link ${url}`);
            handleRedirectLink(url);
        },
        [handleRedirectLink]
    );

    const { initialLink, link } = useLinking({
        source: 'OneLink-AppsFlyer',
        onInitialLink: handleInitialLink,
        onNewLink: handleNewLink,
    });

    return { initialLink, link };
};
